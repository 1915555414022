import React, { useMemo, useState } from 'react';
import { Row, Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';

const BulkActions = ({ rows, actions }: any) => {
  const [loading, setLoading] = useState<any>({});
  const style = useMemo(() => ({ marginBottom: 20, marginLeft: 10 }), []);

  return (
    <Row justify="end">
      {actions.map((action: any, index: number) => {
        if (action.to) {
          return (
            <Link key={action.label} style={style} to={action.to}>
              <Button type={action.type}>{action.label}</Button>
            </Link>
          );
        }
        const isDisabled =
          (action.checkDisabled && action.checkDisabled(rows)) ||
          !rows.length ||
          (action.onlyOne && rows.length > 1);

        const onClick = async () => {
          setLoading((l: any) => ({
            ...l,
            [index]: true,
          }));
          await action.run(rows);
          setLoading((l: any) => ({
            ...l,
            [index]: false,
          }));
        };

        return action.isDropdown ? (
          <Dropdown.Button
            key={action.label}
            overlay={
              <Menu
                items={action.dropdownItems}
                onClick={async ({ key }) => {
                  setLoading((l: any) => ({
                    ...l,
                    [index]: true,
                  }));
                  await action.runItem(rows, key);
                  setLoading((l: any) => ({
                    ...l,
                    [index]: false,
                  }));
                }}
              />
            }
            disabled={isDisabled}
            style={style}
            loading={loading[index]}
            type={action.type}
            trigger={['hover']}
          >
            {action.label}
          </Dropdown.Button>
        ) : (
          <Button
            key={action.label}
            type={action.type}
            style={style}
            loading={loading[index]}
            onClick={onClick}
            disabled={isDisabled}
          >
            {action.label}
          </Button>
        );
      })}
    </Row>
  );
};

export default BulkActions;
