import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import routes from 'constants/routes';

import { QUERY_LIST_MEMBERSHIPS, MUTATION_REMOVE_MEMBERSHIP } from './queries';
import { useMutation } from '@apollo/client';
import { Popconfirm } from 'antd';
import numeral from 'numeral';
import { CURRENCY_FORMAT } from 'constants/numberformats';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';

const Index = () => {
  const { setTableRef, mounted, getColumnSearchProps, refetch } = useTable();
  const { dbUser } = useContext(UserContext);
  const [removeMembership] = useMutation(MUTATION_REMOVE_MEMBERSHIP);

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',

        sorter: true,
        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('name')),

        render: (name: string, record: any) => (
          <Link to={routes.MEMBERSHIPS + '/' + record.level_id + '/edit'}>
            {name}
          </Link>
        ),
      },
      {
        title: 'Rent Duration',
        dataIndex: 'clientMemberships',
        render: (value: [any]) =>
          value.find((v) => !v.corporate_client_id)?.rent_duration_days,
        sorter: true,
        width: 140,
      },
      {
        title: 'Trial Duration (Days)',
        dataIndex: 'clientMemberships',
        render: (value: [any]) =>
          value.find((v) => !v.corporate_client_id)?.trial_length_days,
        sorter: true,
        width: 160,
      },
      {
        title: 'Max Containers/Month',
        dataIndex: 'clientMemberships',
        render: (value: [any]) =>
          value.find((v) => !v.corporate_client_id)?.max_containers_per_month,
        sorter: true,
        width: 160,
      },
      {
        title: '$/Month',
        dataIndex: 'stripe_price',
        render: (value: Record<string, any>) =>
          numeral(value.amount).format(CURRENCY_FORMAT),
        sorter: true,
        width: 140,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: any, record: any) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={async () => {
                await removeMembership({
                  variables: {
                    level_id: record.level_id,
                  },
                });
                refetch();
              }}
            >
              <a href="/#">Remove</a>
            </Popconfirm>
          );
        },
      },
    ];
  }, [mounted]);

  const actions = [
    {
      label: 'Create Membership',
      type: 'primary',
      to: routes.MEMBERSHIPS + '/create',
    },
  ];

  if (dbUser && !dbUser.userPermissions.ManageSettings) {
    return <PermissionsAlert />;
  }

  return (
    <CCCTable
      ref={setTableRef}
      query={QUERY_LIST_MEMBERSHIPS}
      queryKey="listMembershipLevel"
      rowKey="level_id"
      columns={columns}
      actions={actions}
    />
  );
};

export default Index;
