import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import routes from 'constants/routes';

import { QUERY_LIST_LOCATIONS } from './queries';
import { Tag } from 'antd';
import formatAddress from '../../../services/formatAddress';
import { useQuery } from '@apollo/client';
import {
  QUERY_LIST_CAPABILITY,
  QUERY_LIST_CORPORATE_CLIENTS,
  QUERY_LIST_LOCATION_STATUSES,
} from '../../../queries/DictionariesQueries';
import { DATE } from '../../../constants/dateformats';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const MV_CLIENT = "Martha's Vineyard";

const Index = () => {
  const { dbUser } = useContext(UserContext);
  const { setTableRef, mounted, getColumnSearchProps } = useTable();
  const {
    data: capabilities,
    error: capabilitiesError,
    loading: capabilitiesLoading,
  } = useQuery(QUERY_LIST_CAPABILITY);
  const {
    data: locationStatuses,
    error: locationStatusesError,
    loading: locationStatusesLoading,
  } = useQuery(QUERY_LIST_LOCATION_STATUSES);
  const {
    data: corpClients,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      order: [{ field: 'name', order: 'ASC' }],
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });

  const columns = useMemo(() => {
    const hasMoreAccess =
      !dbUser?.isCorporateAdmin ||
      dbUser?.userCorporateClients.find(
        (c) => c.corporateClient.name === MV_CLIENT,
      );
    const extraColumns = hasMoreAccess
      ? [
          {
            title: 'Ready For Checkout',
            dataIndex: 'readyForCheckoutCupsAmount',
            render: (amount: any) => amount || 0,
            onCell: ({
              readyForCheckoutCupsAmount,
              low_cup_threshold,
            }: any) => {
              const result = readyForCheckoutCupsAmount || 0;
              return {
                className:
                  result < low_cup_threshold
                    ? 'danger'
                    : result < low_cup_threshold * 3
                    ? 'warning'
                    : '',
              };
            },
            width: 180,
          },
          {
            title: 'Return Bins Count',
            dataIndex: 'binsAmount',
            render: (amount: number) => amount ?? 0,
            sorter: true,
            width: 120,
          },
          {
            title: 'Last Delivered Date',
            dataIndex: 'last_delivered_on',
            render: (last_delivered_on: string) => {
              return last_delivered_on
                ? moment(last_delivered_on).format(DATE)
                : ' - ';
            },
            width: 180,
          },
          {
            title: 'Last Delivered Containers',
            dataIndex: 'last_delivered_cups',
            width: 180,
          },
        ]
      : [];

    return [
      {
        title: 'Nickname',
        dataIndex: 'pretty_name',
        sorter: true,
        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('pretty_name')),

        render: (name: string, record: any) =>
          dbUser?.userPermissions.ManageLocations ? (
            <Link to={routes.LOCATIONS + '/' + record.location_id + '/edit'}>
              {name}
            </Link>
          ) : (
            name
          ),
        width: 180,
      },
      {
        title: 'Location Name',
        dataIndex: 'name',

        sorter: true,
        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('name')),

        width: 180,
      },
      {
        title: 'Address',
        key: 'address',
        render: (record: any) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/search/?api=1&query=${formatAddress(
              record,
            )}&query_place_id=${record.google_place_id}`}
          >
            {formatAddress(record)}
          </a>
        ),
        width: 180,
      },
      {
        title: 'Status',
        dataIndex: ['status', 'name'],
        key: 'status_id',
        sorter: true,

        filters:
          locationStatuses && !locationStatusesError
            ? locationStatuses.listLocationStatus.map((s: any) => ({
                value: s.status_id,
                text: s.name,
              }))
            : [],
        width: 120,
      },
      {
        title: 'Corporate Client',
        dataIndex: ['corporateClient', 'name'],
        key: 'corporate_client_id',
        sorter: true,
        filters:
          corpClients && !corpClientsError
            ? corpClients.listCorporateClient.map((l: any) => ({
                value: l.corporate_client_id,
                text: l.name,
              }))
            : [],
        width: 180,
      },
      {
        title: 'Capabilities',
        dataIndex: 'capabilities',
        render: (capabilities: any[]) => (
          <span>
            {(capabilities || []).map((cap: any) => (
              <Tag key={cap.capability_id}>{cap.name}</Tag>
            ))}
          </span>
        ),

        filterSubField: ['capability_id'],
        filters:
          capabilities && !capabilitiesError
            ? capabilities.listCapability.map((c: any) => ({
                value: c.capability_id,
                text: c.name,
              }))
            : [],
        width: 180,
      },

      ...extraColumns,
    ];
  }, [capabilities, capabilitiesError, mounted, dbUser]);

  const createLocationAction = {
    label: 'Create Location',
    type: 'primary',
    to: routes.LOCATIONS + '/create',
  };
  const actions = [
    {
      label: 'Create Location Role',
      to: routes.LOCATIONS + '/role/create',
      type: 'primary',
    },
    createLocationAction,
  ];

  if (capabilitiesLoading || corpClientsLoading || locationStatusesLoading) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ViewLocations) {
    return <PermissionsAlert />;
  }

  return (
    <CCCTable
      ref={setTableRef}
      query={QUERY_LIST_LOCATIONS}
      queryKey="listLocation"
      rowKey="location_id"
      columns={columns}
      actions={
        dbUser?.userPermissions.ManageLocations
          ? dbUser?.isCorporateAdmin
            ? [createLocationAction]
            : actions
          : []
      }
    />
  );
};

export default Index;
