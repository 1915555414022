import React from 'react';

import './LinkedinPost.sass';

interface LinkedinPostProps {
  date: string;
  containers: number;
  wasteAvoided: number;
  waterSaved: number;
  co2Saved: number;
}

const getImpactValue = (value: number | undefined) =>
  value && value > 0 ? value.toFixed(1) : 0;

export const LinkedinPost = ({
  date,
  containers,
  wasteAvoided,
  co2Saved,
  waterSaved,
}: LinkedinPostProps) => {
  return (
    <div className="linkedin-post">
      <div className="linkedin-post-content">
        <div className="linkedin-post-content-left">
          <div>
            <h2 className="linkedin-post-title">
              Environmental impact through today
            </h2>
            <h3 className="linkedin-post-date">{date}</h3>
          </div>
          <div>
            <p className="linkedin-post-count">{containers}</p>
            <img
              alt="usefull logo"
              src="/images/Usefull_logo.png"
              className="linkedin-post-usefull-logo"
            />
            <p className="linkedin-post-containers">
              Reusable containers checked out
            </p>
          </div>
          <p className="linkedin-post-description">
            Thank you for doing your part
            <br />
            to help human health and the planet!
          </p>
        </div>

        <div className="linkedin-post-content-right">
          <div className="linkedin-post-stats-header">
            <h2 className="linkedin-post-stats-title">
              Together we have saved:
            </h2>
          </div>

          <div className="linkedin-post-stats-row">
            <div className="linkedin-post-stats-column-left">
              <img
                alt="trash"
                src="/images/trash-truck.png"
                width={109}
                height={77}
                className="linkedin-post-image"
              />
            </div>

            <div className="linkedin-post-stats-column-right">
              <span className="linkedin-post-stats-value">
                {getImpactValue(wasteAvoided)} LBS
              </span>
              <b className="linkedin-post-stats-label">of trash</b>
            </div>
          </div>

          <div className="linkedin-post-stats-row">
            <div className="linkedin-post-stats-column-left">
              <img
                alt="water"
                src="/images/shower-water.png"
                width={77}
                height={77}
                className="linkedin-post-image"
              />
            </div>

            <div className="linkedin-post-stats-column-right">
              <span className="linkedin-post-stats-value">
                {getImpactValue(waterSaved)} GAL
              </span>
              <b className="linkedin-post-stats-label">of water</b>
            </div>
          </div>

          <div className="linkedin-post-stats-row">
            <div className="linkedin-post-stats-column-left">
              <img
                alt="global-warming"
                src="/images/global-warming.png"
                width={77}
                height={77}
                className="linkedin-post-image"
              />
            </div>

            <div className="linkedin-post-stats-column-right">
              <span className="linkedin-post-stats-value">
                {getImpactValue(co2Saved)} LBS
              </span>
              <b className="linkedin-post-stats-label">of emissions</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
