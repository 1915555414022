import React from 'react';
import { Form, Button } from 'antd';
import ContainerTypeSelect from '../../../../components/Select/ContainerTypeSelect';

const TypeForm = ({ handleSubmit, loading }: any) => {
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      name="container_edit"
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Container Type"
        name="type_id"
        rules={[{ required: true, message: 'Please select Container Type!' }]}
      >
        <ContainerTypeSelect placeholder="Search for Container Type" />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={loading}>
        Update Container
      </Button>
    </Form>
  );
};

export default TypeForm;
