import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_CAPABILITY } from 'queries/DictionariesQueries';

const { Option } = Select;

const CapabilitiesSelect = forwardRef(({ value, ...props }: any, ref: any) => {
  const { data, error } = useQuery(QUERY_LIST_CAPABILITY);

  return (
    <Select
      ref={ref}
      value={value}
      mode="multiple"
      style={{ width: '100%' }}
      {...props}
    >
      {data && !error
        ? data.listCapability.map((l: any) => (
            <Option key={l.capability_id} value={`${l.capability_id}`}>
              {l.name}
            </Option>
          ))
        : null}
    </Select>
  );
});

CapabilitiesSelect.displayName = 'CapabilitiesSelect';

export default CapabilitiesSelect;
