import React, { useEffect, useRef, useState } from 'react';
import { InstagramPost } from './InstagramPost';
import { Story } from './Story';
import { LinkedinPost } from './LinkedinPost';
import { useMatch } from 'react-router-dom';
import { CORP_CLIENT_HOSTED_PAGE_QUERY } from 'screens/CorpClientHostedPage/query';
import { useQuery } from '@apollo/client';
import Loader from 'components/Loader/Loader';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { Button, Select } from 'antd';

import './SharebleHostedPage.sass';

type PlatformType = 'instagram-post' | 'instagram-story' | 'linkedin-post';

export const SharebleHostedPage = () => {
  const match = useMatch('/tv/:ccUuid/share');
  const ccUuid = match?.params.ccUuid;
  const printRef = useRef(null);

  const [platformType, setPlatformType] =
    useState<PlatformType>('instagram-post');
  const [{ cups, bowls, co2Saved, wasteAvoided, waterSaved }, setState] =
    useState({
      cups: 0,
      bowls: 0,
      co2Saved: 0,
      wasteAvoided: 0,
      waterSaved: 0,
    });

  const handleDownloadImage = async () => {
    const element = printRef?.current;

    if (element) {
      const canvas = await html2canvas(element, { scale: 1, useCORS: true });

      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = `${platformType}-${Date.now()}.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  const { data, loading, refetch } = useQuery(CORP_CLIENT_HOSTED_PAGE_QUERY, {
    variables: { corpClientUuid: ccUuid || null },
  });

  useEffect(() => {
    if (data) {
      setState({
        cups: data?.hostedPage?.totalCups ?? 0,
        bowls: data?.hostedPage?.totalBowls ?? 0,
        co2Saved: data?.hostedPage?.co2Saved ?? 0,
        wasteAvoided: data?.hostedPage?.wasteAvoided ?? 0,
        waterSaved: data?.hostedPage?.water ?? 0,
      });
    }
  }, [data?.hostedPage, setState]);

  useEffect(() => {
    if (!wasteAvoided) {
      refetch({ corpClientUuid: ccUuid || null });
    }
  }, [wasteAvoided]);

  if (loading) {
    return <Loader />;
  }

  let content;

  if (platformType === 'instagram-post') {
    content = (
      <InstagramPost
        date={moment().format('MMMM DD, YYYY')}
        containers={cups + bowls}
        wasteAvoided={wasteAvoided}
        co2Saved={co2Saved}
        waterSaved={waterSaved}
      />
    );
  } else if (platformType === 'instagram-story') {
    content = (
      <Story
        date={moment().format('MMMM DD, YYYY')}
        containers={cups + bowls}
        wasteAvoided={wasteAvoided}
        co2Saved={co2Saved}
        waterSaved={waterSaved}
      />
    );
  } else if (platformType === 'linkedin-post') {
    content = (
      <LinkedinPost
        date={moment().format('MMMM DD, YYYY')}
        containers={cups + bowls}
        wasteAvoided={wasteAvoided}
        co2Saved={co2Saved}
        waterSaved={waterSaved}
      />
    );
  }

  return (
    <div className="shp-container">
      <div className="shp-sticky-content">
        <div className="shp-instructions">
          <p className="shp-instructions-title">Instructions:</p>
          <p className="shp-instructions-description">
            Once you download the image, log into the social media account and
            upload as normal.
          </p>
        </div>
        <div className="shp-control">
          <Select
            size="large"
            options={[
              { value: 'instagram-post', label: 'Instagram Post' },
              { value: 'instagram-story', label: 'Instagram Story' },
              { value: 'linkedin-post', label: 'LinkedIn Post' },
            ]}
            defaultValue={platformType}
            className="shp-control-select"
            onSelect={setPlatformType}
          />

          <Button type="primary" size="large" onClick={handleDownloadImage}>
            Download
          </Button>
        </div>
      </div>
      <div>
        <div ref={printRef} className="shp-content">
          {content}
        </div>
      </div>
    </div>
  );
};
