import React, { useState, useCallback } from 'react';
import { MUTATION_BULK_UPDATE_BIN, QUERY_LIST_BINS } from '../queries';
import { useMutation } from '@apollo/client';
import { Modal, Form, Col } from 'antd';
import LocationSearchSelect from 'components/Select/LocationSearchSelect';

const BinAssignToLocation = ({ visible, records, onClose }: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [updateBulkBin] = useMutation(MUTATION_BULK_UPDATE_BIN);

  const handleAssignLocation = useCallback(
    async (values: any) => {
      setLoading(true);

      const primaryKeys = records.map((record: any) => ({
        bin_id: record.bin_id,
      }));
      await updateBulkBin({
        variables: {
          primaryKeys,
          input: {
            ...values,
          },
        },
        refetchQueries: [
          {
            query: QUERY_LIST_BINS,
          },
        ],
      });
      setLoading(false);
      onClose(true);
    },
    [records, onClose, updateBulkBin],
  );

  return (
    <Modal
      title="Assign location selected return bins"
      open={visible}
      onOk={form?.submit}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <Form form={form} name="bin_location" onFinish={handleAssignLocation}>
        <div>
          Selected:{' '}
          {records?.length > 25 ? (
            <>
              <b>{records.length}</b> cups.
            </>
          ) : null}
        </div>

        <Col style={{ marginBottom: 20 }}>
          {records?.length <= 25
            ? records.map((c: any, index: number) => (
                <React.Fragment key={c.bin_id}>
                  <b>{c.bin_id}</b>
                  {index !== records.length - 1 ? ', ' : null}
                </React.Fragment>
              ))
            : null}
        </Col>

        <Form.Item
          label="Location"
          name="location_id"
          rules={[{ required: true, message: 'Please select location!' }]}
        >
          <LocationSearchSelect placeholder="Search for Location" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BinAssignToLocation;
