import React, { forwardRef } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_LOCATION_SIMPLE } from 'queries/LocationQueries';

const { Option } = Select;

const LocationSearchSelect = forwardRef(
  ({ allOption, children, disableChildren, ...props }: any, ref: any) => {
    const baseVariables = {
      pagination: {
        size: 100,
        page: 1,
      },
    };
    const {
      data: locations,
      loading,
      error,
      refetch,
    } = useQuery(QUERY_LIST_LOCATION_SIMPLE, {
      variables: {
        where: {},
        pagination: baseVariables.pagination,
      },
    });

    const fetchLocations = debounce(async (value: string) => {
      refetch({
        ...baseVariables,
        where: { name: { contains: value } },
      });
    }, 800);

    return (
      <Select
        ref={ref}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        showSearch
        onSearch={fetchLocations}
        style={{ width: '100%' }}
        {...props}
      >
        {allOption ? (
          <Option key="all" value="all">
            - All -
          </Option>
        ) : null}

        {children}

        {!disableChildren && locations && !error
          ? locations.listLocation.list.map((l: any) => (
              <Option key={l.location_id} value={`${l.location_id}`}>
                {l.name}
              </Option>
            ))
          : null}
      </Select>
    );
  },
);

LocationSearchSelect.displayName = 'LocationSearchSelect';

export default LocationSearchSelect;
