import React, { useState, useMemo, useCallback, useEffect } from 'react';
import auth from '../services/Auth/Firebase';

import {
  AuthContextValue,
  AuthProviderPropsInterface,
} from './AuthContext.types';

export const AuthContext = React.createContext<AuthContextValue>({
  loading: true,

  isSignedIn: false,
  currentUser: null,

  update: () => {},
});

export const AuthProvider = ({ children }: AuthProviderPropsInterface) => {
  const [firstLoading, setFirstLoading] = useState(true);

  const [isSignedIn, setSignedIn] = useState(!!auth.currentUser);
  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  const update = useCallback((user: any) => {
    setSignedIn(!!user);
    setCurrentUser(user);
  }, []);

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      update(user);
      setFirstLoading(false);

      if (user) {
        // print token id for development
        if (process.env.NODE_ENV === 'development')
          console.log(await user.getIdToken());
      }
    });
  }, [isSignedIn, update]);

  const value = useMemo(
    () => ({
      loading: firstLoading,

      isSignedIn,
      currentUser,

      update,
    }),
    [firstLoading, isSignedIn, currentUser, update],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
