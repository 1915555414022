import React, { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { MUTATION_CREATE_LOCATION_ROLE } from './queries';
import LocationRoleForm from './components/LocationRoleForm';
import routes from 'constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_CURRENT_USER, UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';

const CreateLocationRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dbUser } = useContext(UserContext);

  const locationState = location?.state as {
    backTo: string;
    location: any;
    user: any;
  };

  const [createLocationRole] = useMutation(MUTATION_CREATE_LOCATION_ROLE, {
    refetchQueries: [GET_CURRENT_USER],
  });
  const submit = useCallback(
    async ({ ...input }: any) => {
      await createLocationRole({
        variables: {
          input: {
            ...input,
          },
        },
      });
      navigate(
        location.state && locationState?.backTo
          ? locationState?.backTo
          : routes.LOCATIONS,
      );
    },
    [navigate, location.state, createLocationRole, locationState],
  );

  if (
    !dbUser?.userPermissions.ManageLocations ||
    !dbUser?.userPermissions.ManageUsers
  ) {
    return <PermissionsAlert />;
  }

  return (
    <LocationRoleForm
      location={location.state ? locationState?.location : null}
      user={location.state ? locationState?.user : null}
      handleSubmit={submit}
    />
  );
};

export default CreateLocationRole;
