import React, { useContext, useEffect } from 'react';
import { Form, Input, Button, Typography, Col } from 'antd';
import { QRCode } from 'react-qrcode-logo';
import { Buffer } from 'buffer';
import CorporateClientSelect from '../../../../components/Select/CorporateClientSelect';
import LocationSearchSelect from '../../../../components/Select/LocationSearchSelect';
import { UserContext } from '../../../../contexts/UserContext';
import PermissionsAlert from '../../../../components/PermissionsAlert/PermissionsAlert';

const { Title } = Typography;
const { TextArea } = Input;

const BinForm = ({ handleSubmit, loading, bin_id, bin }: any) => {
  const [form] = Form.useForm();
  const { dbUser } = useContext(UserContext);

  useEffect(() => {
    bin && form.setFieldsValue(bin);
  }, [form, bin]);

  if (dbUser && !dbUser.userPermissions.ManageBins) {
    if (dbUser.userPermissions.ViewBins) {
      return (
        <QRCode value={Buffer.from(`bin_id:${bin_id}`).toString('base64')} />
      );
    } else {
      return <PermissionsAlert />;
    }
  }

  return (
    <Form
      layout="vertical"
      name="bins_edit"
      form={form}
      onFinish={handleSubmit}
      initialValues={bin}
    >
      <Col span={24}>
        <Title level={2}>
          Update return bin &quot;<b>{bin_id}</b>&quot;
        </Title>
      </Col>

      <Col span={24}>
        <QRCode value={Buffer.from(`bin_id:${bin_id}`).toString('base64')} />
      </Col>

      <Form.Item label="Belongs to Corporate Client" name="corporate_client_id">
        <CorporateClientSelect
          placeholder="Select Corporate Client"
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Location"
        name="location_id"
        rules={[{ required: true, message: 'Please select location!' }]}
      >
        <LocationSearchSelect placeholder="Search for Location" />
      </Form.Item>

      <Form.Item
        label="Description of where return bin is"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input description of return bin!',
          },
        ]}
      >
        <TextArea
          rows={3}
          style={{ width: '100%' }}
          placeholder="description"
        />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={loading}>
        Update Return Bin
      </Button>
    </Form>
  );
};

export default BinForm;
