import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../../contexts/UserContext';
import PermissionsAlert from '../../../../components/PermissionsAlert/PermissionsAlert';
import { getCorporateClientReportLink } from '../Index';
import { useCorporateClients } from '../../../../hooks/useCorporateClients';

const CorporateClientReport = () => {
  const params = useParams();
  const corporateClientUuId = params.uuid;
  const { dbUser } = useContext(UserContext);
  const { corporateClients, getCorporateClients } = useCorporateClients();

  useEffect(() => {
    getCorporateClients();
  }, [getCorporateClients]);

  const reportLink = useMemo(
    () => getCorporateClientReportLink(corporateClientUuId),
    [corporateClientUuId],
  );

  if (!dbUser) {
    return null;
  }

  if (
    (!dbUser?.userPermissions.BasicReports &&
      !dbUser?.userPermissions.AdminReports) ||
    !corporateClients?.find(
      (c: Record<string, any>) => c.uuid === corporateClientUuId,
    )
  ) {
    return <PermissionsAlert />;
  }

  return <iframe src={reportLink} style={{ width: '100%', height: '100vh' }} />;
};

export default CorporateClientReport;
