import gql from 'graphql-tag';

export const MUTATION_CREATE_BULK_BOX = gql`
  mutation CreateBulkDeliveryBox($input: [DeliveryBoxInput!]!) {
    createBulkDeliveryBox(input: $input) {
      box_id
    }
  }
`;

export const MUTATION_UPDATE_BOX = gql`
  mutation UpdateDeliveryBox($box_id: ID!, $input: DeliveryBoxInput!) {
    updateDeliveryBox(box_id: $box_id, input: $input) {
      box_id
    }
  }
`;

export const MUTATION_UPDATE_LAST_LOCATION_OF_BOX = gql`
  mutation UpdateLastLocationOfBox($box_id: ID!, $location_id: ID!) {
    updateLastLocationOfBox(box_id: $box_id, location_id: $location_id)
  }
`;

export const QUERY_LIST_BOXES = gql`
  query ListBoxes(
    $pagination: PaginationInput
    $where: WhereDeliveryBoxInput
    $order: [OrderInput!]
  ) {
    listDeliveryBox(pagination: $pagination, where: $where, order: $order) {
      total
      list {
        box_id
        unique_name
        corporate_client_id
        location {
          location_id
          name
        }
        corporateClient {
          name
        }

        boxLoads {
          load_id
          deliveries {
            delivery_id
            delivered_on
          }
          boxLoadContainers {
            load_container_id
            container {
              assignments {
                assigned_on
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_LOCATIONS = gql`
  query ListLocation($pagination: PaginationInput, $where: WhereLocationInput) {
    listLocation(pagination: $pagination, where: $where) {
      list {
        location_id
        name
      }
      total
    }
  }
`;

export const QUERY_GET_BOX = gql`
  query GetDeliveryBox($box_id: ID!) {
    getDeliveryBox(box_id: $box_id) {
      box_id
      unique_name
      last_location_id
      boxLoads {
        load_id
        deliveries {
          delivery_id
          delivered_on
        }
        started_on
        boxLoadContainers {
          load_container_id
          container {
            container_id
            assignments {
              assigned_on
            }
          }
        }
      }
    }
  }
`;
