import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_CONTAINER_TYPES } from 'queries/DictionariesQueries';

const { Option } = Select;

const ContainerTypeSelect = forwardRef(({ ...props }: any, ref: any) => {
  const { data, error } = useQuery(QUERY_LIST_CONTAINER_TYPES);

  return (
    <Select ref={ref} style={{ width: '100%' }} {...props}>
      {data && !error
        ? data.listContainerType?.map((l: any) => (
            <Option key={l.type_id} value={`${l.type_id}`}>
              {l.name} {l.corporateClient && `(${l.corporateClient.name})`}
            </Option>
          ))
        : null}
    </Select>
  );
});

ContainerTypeSelect.displayName = 'ContainerTypeSelect';

export default ContainerTypeSelect;
