import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Table,
  Form,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
  Select,
} from 'antd';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { QUERY_LIST_BOXES, MUTATION_CREATE_BULK_BOX } from 'queries/BoxQueries';
import routes from 'constants/routes';
import { EditingField } from 'components/EditingField';
import { UNIQUE_NAME_GENERATOR_QUERIES } from '../../../queries/NameGeneratorQueries';
import { QUERY_LIST_CORPORATE_CLIENTS } from '../../../queries/DictionariesQueries';
import { UserContext } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const { Option } = Select;

const { Title } = Typography;

let boxGenerateIndex = 0;

const BoxBulkCreate = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);

  const client = useApolloClient();
  const [createBulkBox] = useMutation(MUTATION_CREATE_BULK_BOX, {
    refetchQueries: [{ query: QUERY_LIST_BOXES }],
  });
  const {
    data: corpClients,
    error: corpClientsError,
    loading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      order: [{ field: 'name', order: 'ASC' }],
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });

  const [stage, setStage] = useState('generate');
  const [boxes, setBoxes] = useState<Array<any>>([]);
  const [corporateClientId, setCorporateClientId] = useState<number>();

  const generateBox = useCallback(
    ({
      unique_name,
      corporate_client_id,
    }: {
      unique_name: string;
      corporate_client_id: string;
    }) => {
      return {
        id: ++boxGenerateIndex,
        unique_name,
        corporate_client_id: corporate_client_id ?? corporateClientId,
      };
    },
    [corporateClientId],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Delivery Box Name',
        dataIndex: 'unique_name',
        key: 'unique_name',

        render: (unique_name: string, record: any) => (
          <EditingField
            key={`record-${record.id}`}
            value={unique_name}
            onChange={({ target: { value: unique_name } }: any) =>
              setBoxes((list: any[]) => {
                record.unique_name = unique_name;
                list[list.indexOf(record)] = record;
                return [...list];
              })
            }
          />
        ),
      },
    ],
    [],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      setCorporateClientId(values.corporate_client_id);

      const { data } = await client.query({
        query: UNIQUE_NAME_GENERATOR_QUERIES,
        variables: {
          amount: +values.amount,
          corp_client_id: values.corporate_client_id
            ? +values.corporate_client_id
            : null,
        },
        fetchPolicy: 'no-cache',
      });

      setBoxes(
        data?.getUniqueNames.map((unique_name: string) =>
          generateBox({
            unique_name,
            corporate_client_id: values.corporate_client_id,
          }),
        ),
      );
      setStage('create');
    },
    [client, generateBox],
  );

  const handleSaveBoxes = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      setSaving(true);
      await createBulkBox({
        variables: { input: boxes.map((b) => ({ ...b, id: undefined })) },
      });
      setSaving(false);
      setStage('done');

      setTimeout(() => {
        navigate(routes.BOXES);
      }, 1000);
    },
    [navigate, boxes, createBulkBox],
  );

  if (loading) {
    return <Loader />;
  }
  if (!dbUser?.userPermissions.ManageDeliveryBoxes) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10}>
      <Form name="cups_bulk_add" layout="vertical" onFinish={handleSubmit}>
        <Col span={24}>
          <Title level={2}>Generate Delivery Boxes</Title>
        </Col>

        {stage === 'generate' && (
          <>
            <Form.Item
              label="Amount of delivery boxes to generate"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount of delivery boxes to generate!',
                },
              ]}
            >
              <InputNumber
                max={2000}
                min={1}
                style={{ width: '100%' }}
                type="number"
                placeholder="amount of delivery boxes"
              />
            </Form.Item>
            <Form.Item
              label="Property of Corporate Client"
              name="corporate_client_id"
            >
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder="Select Corporate Client"
              >
                {(dbUser?.isCorporateAdmin
                  ? // eslint-disable-next-line no-unsafe-optional-chaining
                    dbUser?.userCorporateClients
                  : corpClients && !corpClientsError
                  ? corpClients.listCorporateClient
                  : []
                ).map((l: any) => (
                  <Option
                    key={l.corporate_client_id}
                    value={`${l.corporate_client_id}`}
                  >
                    {dbUser?.isCorporateAdmin ? l.corporateClient.name : l.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Generate Delivery Boxes
            </Button>
          </>
        )}

        {stage === 'create' && (
          <>
            <Table rowKey="id" dataSource={boxes} columns={columns} />
            <Row gutter={15}>
              <Col span={2}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={saving}
                  onClick={handleSaveBoxes}
                >
                  Save Delivery Boxes
                </Button>
              </Col>
            </Row>
          </>
        )}

        {stage === 'done' && (
          <Col>
            <Title style={{ margin: '20px auto' }} level={3}>
              Done!
            </Title>
          </Col>
        )}
      </Form>
    </Row>
  );
};

export default BoxBulkCreate;
