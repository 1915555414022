import React, { forwardRef, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Select, Spin } from 'antd';
import { useJsApiLoader } from '@react-google-maps/api';

export const GOOGLE_MAPS_VERSION = 'quarterly';
const GOOGLE_MAPS_API_KEY = process.env
  .REACT_APP_GOOGLE_PLACE_API_KEY as string;
const GOOGLE_MAPS_LIBRARIES = ['places'] as (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[];

function toAddress(place: any) {
  const raw = {
    street_number: place.address_components.find((c: any) =>
      c.types.includes('street_number'),
    ),
    route: place.address_components.find((c: any) => c.types.includes('route')),

    city: place.address_components.find((c: any) =>
      c.types.includes('locality'),
    ),
    neighborhood: place.address_components.find((c: any) =>
      c.types.includes('neighborhood'),
    ),
    postal_code: place.address_components.find((c: any) =>
      c.types.includes('postal_code'),
    ),
    state: place.address_components.find((c: any) =>
      c.types.includes('administrative_area_level_1'),
    ),
    country: place.address_components.find((c: any) =>
      c.types.includes('country'),
    ),
  };

  return {
    address_line_1:
      (raw.street_number ? raw.street_number.long_name : '') +
      ' ' +
      (raw.route ? raw.route.long_name : ''),
    address_line_2: null,
    city: raw.city
      ? raw.city.long_name
      : raw.neighborhood
      ? raw.neighborhood.short_name
      : null,
    state: raw.state ? raw.state.short_name : null,
    country: raw.country ? raw.country.short_name : null,
    postal_code: raw.postal_code ? raw.postal_code.long_name : null,

    google_place_id: place.place_id,
  };
}

const GooglePlaceAutocomplete = forwardRef(
  ({ value, onChange }: any, ref: any) => {
    const [search, setSearch] = useState('');

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
      libraries: GOOGLE_MAPS_LIBRARIES,
      version: GOOGLE_MAPS_VERSION,
    });

    if (!isLoaded) {
      return <>Loading google maps...</>;
    }

    const handleSelect = async (name: string) => {
      setSearch(name);

      const results = await geocodeByAddress(name);
      const place = results[0];
      const latLng = await getLatLng(place);

      const value = {
        name,
        addressProperties: {
          ...toAddress(place),
          ...latLng,
        },
        raw: results,
      };

      onChange({ target: { value } });
    };

    return (
      <PlacesAutocomplete
        value={search || value || ''}
        onChange={(...args) => {
          setSearch(args[0]);
        }}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: 'us' },
        }}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => {
          const searchInputProps = getInputProps({
            placeholder: 'Search Places ...',
            className: 'location-search-input',
          });
          const searchOnChange = searchInputProps.onChange;
          delete searchInputProps.onChange;

          return (
            <Select
              ref={ref}
              notFoundContent={loading ? <Spin size="small" /> : null}
              filterOption={false}
              showSearch
              {...searchInputProps}
              onSearch={(value: string) =>
                searchOnChange({ target: { value } })
              }
              onChange={handleSelect}
            >
              {suggestions.map((suggestion: any) => (
                <Select.Option
                  key={suggestion.placeId}
                  value={suggestion.description}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <span>{suggestion.description}</span>
                </Select.Option>
              ))}
            </Select>
          );
        }}
      </PlacesAutocomplete>
    );
  },
);

GooglePlaceAutocomplete.displayName = 'GooglePlaceAutocomplete';

export default GooglePlaceAutocomplete;
