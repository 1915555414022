import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import moment from 'moment';

import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';

import routes from 'constants/routes';
import { DATE, TIME } from 'constants/dateformats';

import {
  QUERY_LOCATIONS,
  QUERY_CONTAINER_STATUSES,
  QUERY_LIST_CONTAINERS,
  MUTATION_SET_QR_BATCH,
  PURE_QUERY_LIST_CONTAINERS,
  MUTATION_SET_CORPORATE_CLIENT_TO_CONTAINERS,
  QUERY_GET_QR_BATCHES,
} from './queries';
import AssignLocationAndStatusModal from './components/AssignLocationAndStatusModal';
import ChangeStatusModal from './components/ChangeStatusModal';
import {
  QUERY_LIST_CONTAINER_TYPES,
  QUERY_LIST_CORPORATE_CLIENTS,
} from '../../../queries/DictionariesQueries';
import { UserContext } from '../../../contexts/UserContext';
import { useMutation } from '@apollo/client';
import printOptions from '../../../constants/PrintOptions';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const Index = () => {
  const { dbUser } = useContext(UserContext);
  const {
    setTableRef,
    mounted,
    refetch,
    setSelectedRowKeys,
    getColumnSearchProps,
    getColumnNumberSearch,
    getColumnRadioFilterProps,
  } = useTable();
  const [assignLocationAndStatusRecords, setAssignLocationAndStatusRecords] =
    useState<any[]>([]);
  const [changeStatus, setChangeStatus] = useState<any>();
  const [isHoveringDate, setIsHoveringDate] = useState(false);

  const {
    data: locations,
    error: locationsError,
    loading: locationsLoading,
  } = useQuery(QUERY_LOCATIONS);
  const {
    data: cupStatuses,
    error: cupStatusesError,
    loading: cupStatusesLoading,
  } = useQuery(QUERY_CONTAINER_STATUSES);
  const {
    data: containerTypes,
    error: containerTypesError,
    loading: containerTypesLoading,
  } = useQuery(QUERY_LIST_CONTAINER_TYPES);
  const {
    data: corpClients,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      order: [{ field: 'name', order: 'ASC' }],
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });

  const {
    data: qrBatchesData,
    error: qrBatchesError,
    loading: qrBatchesLoading,
  } = useQuery(QUERY_GET_QR_BATCHES);

  const [setQrBatch] = useMutation(MUTATION_SET_QR_BATCH, {
    refetchQueries: [PURE_QUERY_LIST_CONTAINERS],
  });

  const [setCorporateClientToContainers] = useMutation(
    MUTATION_SET_CORPORATE_CLIENT_TO_CONTAINERS,
    {
      refetchQueries: [PURE_QUERY_LIST_CONTAINERS],
    },
  );

  const columns = useMemo(
    () => [
      {
        title: 'Container Name',
        dataIndex: 'unique_name',
        render: (unique_name: string, record: any) => (
          <Link to={routes.CONTAINERS + '/' + record.container_id}>
            {unique_name}
          </Link>
        ),
        sorter: true,
        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('unique_name')),
        width: 160,
      },
      {
        title: 'Type',
        dataIndex: ['type', 'name'],
        key: 'type_id',
        sorter: true,

        filters:
          containerTypes && !containerTypesError
            ? [
                ...containerTypes.listContainerType.map((s: any) => ({
                  value: s.type_id,
                  text: s.name,
                })),
                {
                  value: null,
                  text: 'NULL',
                },
              ]
            : [],
        width: 120,
      },
      {
        title: 'SKU',
        dataIndex: ['type', 'sku'],
        key: 'type.sku',
        sorter: true,

        filters:
          containerTypes && !containerTypesError
            ? [
                ...containerTypes.listContainerType
                  .filter((t: any) => t.sku)
                  .map((t: any) => ({
                    value: t.sku,
                    text: t.sku,
                  })),
                {
                  value: null,
                  text: 'NULL',
                },
              ]
            : [],
        width: 100,
      },
      {
        title: 'Status',
        dataIndex: ['status', 'name'],
        key: 'current_status_id',
        sorter: true,

        filters:
          cupStatuses && !cupStatusesError
            ? cupStatuses.listContainerStatus.map((s: any) => ({
                value: s.status_id,
                text: s.name,
              }))
            : [],
        width: 120,
      },
      {
        title: 'Status Updated On',
        dataIndex: 'status_updated_on',
        key: 'status_updated_on',
        sorter: true,
        render: (status_updated_on: string) =>
          moment(status_updated_on).format(DATE),
        width: 150,
      },
      {
        title: 'Corporate Client',
        dataIndex: ['corporateClient', 'name'],
        key: 'corporate_client_id',
        sorter: true,
        filters:
          corpClients && !corpClientsError
            ? [
                ...corpClients.listCorporateClient.map((l: any) => ({
                  value: l.corporate_client_id,
                  text: l.name,
                })),
                ...(dbUser?.isCorporateAdmin
                  ? []
                  : [
                      {
                        value: null,
                        text: 'NOT ASSIGNED',
                      },
                    ]),
              ]
            : [],
        width: 180,
      },
      {
        title: 'Last Location',
        dataIndex: ['last_location', 'name'],
        key: 'last_location_id',
        sorter: true,

        render: (loc_name: string, { last_location }: any) =>
          last_location ? (
            dbUser?.userPermissions.ManageLocations ? (
              <Link
                to={
                  routes.LOCATIONS + '/' + last_location.location_id + '/edit'
                }
              >
                {loc_name}
              </Link>
            ) : (
              loc_name
            )
          ) : null,

        filters:
          locations && !locationsError
            ? locations.listLocation.list.map((l: any) => ({
                value: l.location_id,
                text: l.name,
              }))
            : [],
        width: 160,
      },
      {
        title: 'Number Of Assignments',
        dataIndex: 'assignments_amount',
        sorter: true,
        width: 180,
        ...(mounted && getColumnNumberSearch('assignments amount')),
      },
      {
        title: 'QRCode printed on',
        dataIndex: 'qr_code_printed_on',
        sorter: true,
        render: (qr_code_printed_on: string) =>
          qr_code_printed_on
            ? moment(qr_code_printed_on).format(DATE)
            : 'not printed',

        filterOperation: 'isNull',
        filters: [
          {
            value: true,
            text: 'Not set',
          },
          {
            value: false,
            text: 'Exist',
          },
        ],
        ...(mounted && getColumnRadioFilterProps('qr_code_printed_on')),
        width: 180,
      },
      {
        title: 'QR Batch',
        dataIndex: 'qr_batch',
        sorter: true,
        render: (qr_batch: string) => (qr_batch ? qr_batch : 'not assigned'),

        filters: (qrBatchesData && !qrBatchesError
          ? qrBatchesData.qrBatchList
          : []
        ).map((batch: number) => ({
          value: batch,
          text: batch ? batch.toString() : 'NOT ASSIGNED',
        })),
        width: 180,
      },
      {
        title: 'Created on',
        dataIndex: 'created_at',
        sorter: true,
        render: (date: string) => (
          <div>
            <div
              onMouseOver={() => setIsHoveringDate(true)}
              onMouseOut={() => setIsHoveringDate(false)}
            >
              {moment(date).format(DATE)}
            </div>
            {isHoveringDate && <div>{moment(date).format(TIME)}</div>}
          </div>
        ),
        width: 180,
      },
    ],
    //eslint-disable-next-line
    [cupStatuses, cupStatusesError, locations, locationsError, mounted],
  );

  const actions: any[] = [
    {
      label: 'Change status',
      onlyOne: true,
      run: ([cup]: any[]) => {
        setChangeStatus(cup);
      },
    },
    {
      label: 'Print QR Codes',
      type: 'primary',
      isDropdown: true,
      dropdownItems: printOptions,
      checkDisabled: (records: any[]) =>
        records?.length > 1 &&
        !records.every(
          (record, index, array) =>
            index === array.length - 1 ||
            record?.type?.type_id === array[index + 1]?.type?.type_id,
        ),
      runItem: async (records: any[], key: string) => {
        const api = process.env.REACT_APP_GRAPHQL_SERVER_URI || '';
        const container_ids = records.map((c) => c.container_id);
        const qrBatchResult = await setQrBatch({
          variables: { container_ids },
        });

        if (qrBatchResult.data.setQrBatch) {
          window.open(
            api.replace('/graphql', '') +
              `/print?type=container&format=${key}&batch_ids=` +
              qrBatchResult.data.setQrBatch.join(','),
            '_blank',
          );
        }
      },
    },
    {
      label: 'Set Corporate Client',
      type: 'primary',
      isDropdown: true,
      dropdownItems:
        corpClients && !corpClientsError
          ? corpClients.listCorporateClient.map((l: any) => ({
              label: l.name,
              key: l.corporate_client_id,
            }))
          : [],
      runItem: async (records: any[], key: string) => {
        await setCorporateClientToContainers({
          variables: {
            container_ids: records.map((c) => c.container_id),
            corporate_client_id: key,
          },
        });
      },
    },
    {
      label: 'Create Containers',
      type: 'primary',
      to: routes.CONTAINERS + '/create',
    },
  ];

  if (
    qrBatchesLoading ||
    cupStatusesLoading ||
    corpClientsLoading ||
    locationsLoading ||
    containerTypesLoading
  ) {
    return <Loader />;
  }

  if (
    !dbUser?.userPermissions.ManageCups &&
    !dbUser?.userPermissions.ViewCups
  ) {
    return <PermissionsAlert />;
  }

  return (
    <>
      <AssignLocationAndStatusModal
        visible={!!assignLocationAndStatusRecords?.length}
        records={assignLocationAndStatusRecords}
        onClose={async (updated: boolean) => {
          setAssignLocationAndStatusRecords([]);

          if (updated) {
            setSelectedRowKeys([]);
            refetch();
          }
        }}
      />
      <ChangeStatusModal
        visible={!!changeStatus}
        record={changeStatus}
        onClose={async (updated: boolean) => {
          setChangeStatus(false);

          if (updated) {
            setSelectedRowKeys([]);
            refetch();
          }
        }}
      />
      <CCCTable
        ref={setTableRef}
        query={QUERY_LIST_CONTAINERS}
        queryKey="listContainer"
        rowKey="container_id"
        columns={columns}
        actions={dbUser?.userPermissions.ManageCups ? actions : []}
      />
    </>
  );
};

export default Index;
