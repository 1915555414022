import React, { useCallback, useContext, useMemo } from 'react';
import { Typography, Row, Col } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { QRCode } from 'react-qrcode-logo';
import { Buffer } from 'buffer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  MUTATION_UPDATE_LAST_LOCATION_OF_BOX,
  QUERY_GET_BOX,
  QUERY_LIST_BOXES,
} from 'queries/BoxQueries';
import { QUERY_LIST_LOAD } from 'queries/LoadQueries';

import CCCTable from 'components/CCCTable/CCCTable';
import { FULL_DATE } from 'constants/dateformats';
import BoxForm from './components/BoxForm';
import routes from 'constants/routes';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import _ from 'lodash';

const { Title } = Typography;

const BoxView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);

  const { data, error, loading } = useQuery(QUERY_GET_BOX, {
    variables: {
      box_id: params.box_id,
    },
  });

  const [updateBox, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_LAST_LOCATION_OF_BOX,
    {
      refetchQueries: [
        { query: QUERY_LIST_BOXES },
        { query: QUERY_GET_BOX, variables: { box_id: params.box_id } },
      ],
    },
  );

  const loadColumns = useMemo(
    () => [
      {
        title: 'Load ID',
        dataIndex: 'load_id',
        sorter: true,
        render: (load_id: number) => (
          <Link to={`/loads/${load_id}`}>{load_id}</Link>
        ),
      },
      {
        title: 'Started on',
        dataIndex: 'started_on',
        sorter: true,
        render: (date: any) => moment(date).format(FULL_DATE),
      },
    ],
    [],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      await updateBox({
        variables: {
          box_id: params.box_id,
          location_id: values.last_location_id,
        },
      });
      setTimeout(() => navigate(routes.BOXES), 1000);
    },
    [navigate, updateBox, params, data],
  );

  const box = data?.getDeliveryBox;

  const canLocationBeUpdated = useMemo(() => {
    let res =
      dbUser?.userPermissions.ManageDeliveryBoxes && !!box?.boxLoads?.length;
    if (res) {
      const { boxLoadContainers, started_on } =
        box.boxLoads[box.boxLoads.length - 1];
      const lastLoadDate = started_on;

      const allAssignments =
        boxLoadContainers?.length &&
        boxLoadContainers
          .map((c: Record<string, any>) => c.container.assignments)
          .flat(1);

      const sortedAssignments =
        allAssignments?.length &&
        _.sortBy(
          allAssignments.filter((a: Record<string, any>) => a.assigned_on),
          'assigned_on',
        );

      const lastAssignmentDate =
        sortedAssignments?.length &&
        sortedAssignments[sortedAssignments.length - 1]?.assigned_on;

      const isContainerWasCheckedOutAfterLoad =
        lastAssignmentDate && lastAssignmentDate > lastLoadDate;
      res = !isContainerWasCheckedOutAfterLoad;
    }
    return res;
  }, [box?.box_id, dbUser?.userPermissions]);

  if (loading) {
    return <Title level={3}>loading</Title>;
  }

  if (error) {
    return (
      <Title level={3}>
        ERROR: <b>{error.message}</b>
      </Title>
    );
  }

  if (
    !dbUser?.userPermissions.ManageDeliveryBoxes &&
    !dbUser?.userPermissions.ViewDeliveryBoxes
  ) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10}>
      <Col span={24}>
        <Title level={2}>
          View Box &quot;<b>{box.unique_name}</b>&quot;
        </Title>
      </Col>

      <Col span={24}>
        <Row gutter={10}>
          <Col span={8}>
            <QRCode
              value={Buffer.from(`box_id:${box.box_id}`).toString('base64')}
            />
          </Col>
          {canLocationBeUpdated && (
            <Col span={16}>
              <BoxForm
                loading={updateLoading}
                box={box}
                handleSubmit={handleSubmit}
              />
            </Col>
          )}
        </Row>
      </Col>

      <CCCTable
        rowKey="load_id"
        columns={loadColumns}
        query={QUERY_LIST_LOAD}
        queryKey="listBoxLoad"
        where={{
          box_id: { eq: params.box_id },
        }}
      />
    </Row>
  );
};

export default BoxView;
