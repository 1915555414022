import React, { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { MUTATION_CREATE_TOAST_MESSAGE } from './queries';
import ToastMessageForm from './components/ToastMessageForm';
import routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';

const ToastCreate = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [createToastMessage] = useMutation(MUTATION_CREATE_TOAST_MESSAGE);

  const submit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ capabilities, ...input }: any) => {
      await createToastMessage({
        variables: {
          input,
        },
      });

      navigate(routes.TOAST_MESSAGES);
    },
    [navigate, createToastMessage],
  );

  if (dbUser && !dbUser.userPermissions.ManageSettings) {
    return <PermissionsAlert />;
  }

  return <ToastMessageForm handleSubmit={submit} />;
};

export default ToastCreate;
