import gql from 'graphql-tag';

export const QUERY_ASSIGNMENT_FEE_LEDGER = gql`
  query GetFeeLedger($assignment_id: ID!) {
    getFeeLedger(assignment_id: $assignment_id) {
      record_id
      amount
      assessed_on
      processed_on
      note
      is_voided
      assignment {
        container {
          unique_name
          container_id
        }
      }
    }
  }
`;

export const QUERY_USER_FEE_LEDGER = gql`
  query GetFeeLedgerByUser($user_id: ID!) {
    getFeeLedgerByUser(user_id: $user_id) {
      record_id
      assignment_id
      amount
      assessed_on
      processed_on
      note
      is_voided
      assignment {
        container {
          unique_name
          container_id
        }
      }
    }
  }
`;

export const MUTATION_VOID_FEE_LEDGER_RECORD = gql`
  mutation VoidFeeLedgerRecord($record_id: ID!) {
    voidFeeLedgerRecord(record_id: $record_id)
  }
`;
