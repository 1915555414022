import React, { useEffect } from 'react';
import Logo from '../../components/Logo';

import auth from '../../services/Auth/Firebase';

const NotFound = () => {
  useEffect(() => {
    auth.signOut();
  }, []);

  return (
    <div className="auth-wrapper">
      <Logo className="auth-wrapper__logo" />
      <div className="auth-wrapper__title">403: Not Enough permissions</div>
    </div>
  );
};

export default NotFound;
