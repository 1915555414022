import React from 'react';

import './InstagramPost.sass';

interface InstagramPostProps {
  date: string;
  containers: number;
  wasteAvoided: number;
  waterSaved: number;
  co2Saved: number;
}

const getImpactValue = (value: number | undefined) =>
  value && value > 0 ? value.toFixed(1) : 0;

export const InstagramPost = ({
  date,
  containers,
  wasteAvoided,
  co2Saved,
  waterSaved,
}: InstagramPostProps) => {
  return (
    <div className="insta-post">
      <div className="insta-post-content">
        <div className="insta-post-content-top">
          <h2 className="insta-post-title">
            Environmental impact through today
          </h2>
          <h3 className="insta-post-date">{date}</h3>
          <p className="insta-post-count">{containers}</p>
          <img
            alt="usefull logo"
            src="/images/Usefull_logo.png"
            className="insta-post-usefull-logo"
          />
          <p className="insta-post-containers">
            Reusable containers checked out
          </p>
        </div>

        <div className="insta-post-content-divider" />

        <div className="insta-post-content-bottom">
          <h2 className="insta-post-stats-title">Together we have saved:</h2>

          <div className="insta-post-stats">
            <div className="insta-post-stats-item">
              <img
                alt="trash"
                src="/images/trash-truck.png"
                width={195}
                height={138}
                className="insta-post-image"
              />
              <span className="insta-post-stats-value">
                {getImpactValue(wasteAvoided)} LBS
              </span>
              <b className="insta-post-stats-label">of trash</b>
            </div>

            <div className="insta-post-stats-item">
              <img
                alt="water"
                src="/images/shower-water.png"
                width={149}
                height={149}
                className="insta-post-image"
              />
              <span className="insta-post-stats-value">
                {getImpactValue(waterSaved)} GAL
              </span>
              <b className="insta-post-stats-label">of water</b>
            </div>

            <div className="insta-post-stats-item">
              <img
                alt="global-warming"
                src="/images/global-warming.png"
                width={146}
                height={146}
                className="insta-post-image"
              />
              <span className="insta-post-stats-value">
                {getImpactValue(co2Saved)} LBS
              </span>
              <b className="insta-post-stats-label">of emissions</b>
            </div>
          </div>
        </div>
      </div>
      <p className="insta-post-description">
        Thank you for doing your part to help human health and the planet!
      </p>
    </div>
  );
};
