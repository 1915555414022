import gql from 'graphql-tag';

export const MUTATION_CREATE_BULK_BIN = gql`
  mutation CreateBulkBin($input: [CollectionBinInput!]!) {
    createBulkCollectionBin(input: $input) {
      bin_id
    }
  }
`;
export const MUTATION_BULK_UPDATE_BIN = gql`
  mutation UpdateBulkBin(
    $primaryKeys: [CollectionBinPrimaryKeys!]!
    $input: CollectionBinInput!
  ) {
    updateBulkCollectionBin(primaryKeys: $primaryKeys, input: $input)
  }
`;

export const MUTATION_UPDATE_BIN = gql`
  mutation UpdateBin($bin_id: ID!, $input: CollectionBinInput!) {
    updateCollectionBin(bin_id: $bin_id, input: $input) {
      bin_id
    }
  }
`;

export const MUTATION_REMOVE_BIN = gql`
  mutation UpdateBin($bin_id: ID!) {
    removeCollectionBin(bin_id: $bin_id)
  }
`;

export const QUERY_GET_BIN = gql`
  query GetBin($bin_id: ID!) {
    getCollectionBin(bin_id: $bin_id) {
      bin_id
      description
      location_id
      corporate_client_id
    }
  }
`;

export const PURE_QUERY_LIST_BINS = 'ListBins';
export const QUERY_LIST_BINS = gql`
  query ListBins(
    $pagination: PaginationInput
    $where: WhereCollectionBinInput
    $order: [OrderInput!]
  ) {
    listCollectionBin(pagination: $pagination, where: $where, order: $order) {
      total
      list {
        bin_id
        location_id
        corporate_client_id
        corporateClient {
          corporate_client_id
          name
        }
        location {
          location_id
          name
          pretty_name
        }
        description
        assigned_on
      }
    }
  }
`;

export const MUTATION_SET_CORPORATE_CLIENT_TO_BINS = gql`
  mutation SetCorporateClientToBins(
    $bin_ids: [ID!]!
    $corporate_client_id: ID!
  ) {
    setCorporateClientToBins(
      bin_ids: $bin_ids
      corporate_client_id: $corporate_client_id
    )
  }
`;
