import React, { useContext, useMemo } from 'react';
import CCCTable from '../CCCTable/CCCTable';
import moment from 'moment';
import routes from 'constants/routes';
import numeral from 'numeral';
import { CURRENCY_FORMAT } from 'constants/numberformats';
import { useQuery } from '@apollo/client';
import { QUERY_PAYMENT_PROCESSORS } from 'queries/MembershipLevel';
import {
  QUERY_ASSIGNMENTS,
  QUERY_ASSIGNMENT_STATUSES,
} from 'queries/AssignmentsQueries';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { FIELDS_NAMES } from './const';
import { QUERY_LIST_CORPORATE_CLIENTS } from 'queries/DictionariesQueries';
import useTable from '../CCCTable/useTable';
import _ from 'lodash';
import PermissionsAlert from '../PermissionsAlert/PermissionsAlert';
import Loader from '../Loader/Loader';

interface IAssignmentTable {
  where?: Record<string, any>;
  hiddenColumns?: FIELDS_NAMES[];
}

const AssignmentTable = ({ where, hiddenColumns }: IAssignmentTable) => {
  const { dbUser } = useContext(UserContext);
  const { mounted, setTableRef, getColumnSearchProps, getColumnNumberSearch } =
    useTable();

  const {
    data: assignmentStatuses,
    error: assignmentStatusesError,
    loading: assignmentStatusesLoading,
  } = useQuery(QUERY_ASSIGNMENT_STATUSES, { fetchPolicy: 'no-cache' });

  const {
    data: paymentProcessors,
    error: paymentProcessorsError,
    loading: paymentProcessorLoading,
  } = useQuery(QUERY_PAYMENT_PROCESSORS, { fetchPolicy: 'no-cache' });

  const {
    data: corpClients,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      order: [{ field: 'name', order: 'ASC' }],
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });

  const columns = useMemo(() => {
    return [
      {
        title: FIELDS_NAMES.STATUS,
        dataIndex: ['status', 'name'],
        render: (name: string) => name[0].toUpperCase() + name.slice(1),
        key: 'status_id',
        sorter: true,

        filters:
          assignmentStatuses && !assignmentStatusesError
            ? assignmentStatuses.listAssignmentStatus.map((s: any) => ({
                value: s.status_id,
                text: s.name[0].toUpperCase() + s.name.slice(1),
              }))
            : [],
        width: 140,
      },
      {
        title: FIELDS_NAMES.CONTAINER,
        dataIndex: ['container', 'unique_name'],
        key: 'container.unique_name',
        sorter: true,

        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('container name')),

        render: (name: string, record: any) =>
          dbUser?.userPermissions.ManageCups ||
          dbUser?.userPermissions.ViewCups ? (
            <Link to={routes.CONTAINERS + '/' + record.container.container_id}>
              {name}
            </Link>
          ) : (
            name
          ),
        width: 180,
      },
      {
        title: FIELDS_NAMES.CORP_CLIENT,
        dataIndex: ['container', 'corporateClient', 'name'],
        key: 'container.corporate_client_id',
        sorter: true,
        filters:
          corpClients && !corpClientsError
            ? [
                ...corpClients.listCorporateClient.map((l: any) => ({
                  value: l.corporate_client_id,
                  text: l.name,
                })),
                ...(dbUser?.isCorporateAdmin
                  ? []
                  : [
                      {
                        value: null,
                        text: 'NOT ASSIGNED',
                      },
                    ]),
              ]
            : [],
        width: 180,
      },
      {
        title: FIELDS_NAMES.USER,
        dataIndex: ['user', 'full_name'],
        key: 'user.first_name|user.last_name|user.email',
        sorter: 'user.first_name',

        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('user, fullname, email')),

        render: (name: string, record: any) => {
          if (dbUser?.isCorporateAdmin) {
            return record.user ? name : '- no user -';
          }

          return record.user ? (
            dbUser?.userPermissions.ManageUsers ? (
              <Link to={`${routes.USERS}/${record.user.user_id}`}>{name}</Link>
            ) : (
              name
            )
          ) : (
            '- no user -'
          );
        },
        width: 180,
      },
      {
        title: FIELDS_NAMES.RETURNED_BY_USER,
        dataIndex: ['returned_by_user', 'full_name'],
        key: 'returned_by_user.first_name|returned_by_user.last_name|returned_by_user.email',
        sorter: 'returned_by_user.first_name',

        filterOperation: 'contains',

        ...(mounted &&
          getColumnSearchProps('returned_by_user, full_name, email')),

        render: (name: string, record: any) => {
          if (dbUser?.isCorporateAdmin) {
            return record.returned_by_user ? name : '- no user -';
          }

          return record.returned_by_user ? (
            dbUser?.userPermissions.ManageUsers ? (
              <Link to={`${routes.USERS}/${record.returned_by_user.user_id}`}>
                {name}
              </Link>
            ) : (
              name
            )
          ) : (
            '- no user -'
          );
        },
        width: 180,
      },
      {
        title: FIELDS_NAMES.MEMBERSHIP_LEVEL,
        dataIndex: ['user', 'current_client_level', 'membershipLevel', 'name'],
        width: 180,
      },
      {
        title: FIELDS_NAMES.FROM_LOCATION,
        dataIndex: ['from_location', 'pretty_name'],
        key: 'from_location.pretty_name',
        sorter: true,

        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('location')),

        render: (name: string, r: any) =>
          name ? (
            dbUser?.userPermissions.ManageLocations ? (
              <Link to={routes.LOCATIONS + '/' + r.from_location_id + '/edit'}>
                {name}
              </Link>
            ) : (
              name
            )
          ) : (
            '- not set -'
          ),
        width: 180,
      },
      {
        title: FIELDS_NAMES.TO_LOCATION,
        dataIndex: ['to_location', 'pretty_name'],
        key: 'to_location.pretty_name',
        sorter: true,

        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('location')),

        render: (name: string, r: any) =>
          name ? (
            dbUser?.userPermissions.ManageLocations ? (
              <Link to={routes.LOCATIONS + '/' + r.to_location_id + '/edit'}>
                {name}
              </Link>
            ) : (
              name
            )
          ) : (
            '- not set -'
          ),
        width: 180,
      },
      {
        title: FIELDS_NAMES.ASSIGNED_ON,
        dataIndex: 'assigned_on',
        render: (data: string) =>
          data ? moment(data).format('LLL') : '- not set -',
        sorter: true,
        width: 180,
      },
      {
        title: FIELDS_NAMES.DUE_ON,
        dataIndex: 'due_on',
        render: (d: string, value: Record<string, any>) => (
          <span
            style={{
              color:
                !value.returned_on && moment().isAfter(value.due_on)
                  ? 'red'
                  : 'black',
            }}
          >
            {d
              ? `${moment(d).format('LL')} (${moment(d).fromNow()})`
              : '- not set -'}
          </span>
        ),
        sorter: true,
        width: 180,
      },
      {
        title: FIELDS_NAMES.RETURNED_ON,
        dataIndex: 'returned_on',
        render: (d: string, value: Record<string, any>) => (
          <span
            style={{
              color:
                (!value.returned_on && moment().isAfter(value.due_on)) ||
                value.returned_on > value.due_on
                  ? 'red'
                  : 'black',
            }}
          >
            {d ? `${moment(d).format('LLL')}` : '- not returned -'}
          </span>
        ),
        sorter: true,
        width: 180,
      },
      {
        title: FIELDS_NAMES.PAYMENT_PROCESSOR,
        dataIndex: ['payment_processor_id'],
        key: 'payment_processor_id',
        sorter: true,
        filters:
          paymentProcessors && !paymentProcessorsError
            ? paymentProcessors.listPaymentProcessor.map((pp: any) => ({
                value: pp.processor_id,
                text: pp.name,
              }))
            : [],
        render: (id: number) => {
          const paymentProcessorName =
            paymentProcessors &&
            !paymentProcessorsError &&
            paymentProcessors.listPaymentProcessor.find(
              (p: any) => p.processor_id === id,
            )?.name;

          return paymentProcessorName || '- unknown -';
        },
        width: 120,
      },
      {
        title: FIELDS_NAMES.TOTAL_FEES,
        key: 'total fees',
        dataIndex: 'feeLedgerRecords',
        render: (value: Record<string, any>[]) =>
          numeral(_.sumBy(value, (r: Record<string, any>) => r.amount)).format(
            CURRENCY_FORMAT,
          ),
        width: 120,
      },
      {
        title: FIELDS_NAMES.CHARGED_LATE_FEES,
        dataIndex: 'feeLedgerRecords',
        key: 'charged late fees',
        render: (value: Record<string, any>[]) =>
          numeral(
            _.sumBy(
              value.filter((r: Record<string, any>) => r.processed_on),
              (r: Record<string, any>) => r.amount,
            ),
          ).format(CURRENCY_FORMAT),
        width: 120,
      },
      {
        title: FIELDS_NAMES.UNCHARGED_LATE_FEES,
        dataIndex: 'feeLedgerRecords',
        key: 'uncharged late fees',
        render: (value: Record<string, any>[]) =>
          numeral(
            _.sumBy(
              value.filter((r: Record<string, any>) => !r.processed_on),
              (r: Record<string, any>) => r.amount,
            ),
          ).format(CURRENCY_FORMAT),
        width: 120,
      },
      {
        title: FIELDS_NAMES.REFUNDED_AMOUNT,
        dataIndex: 'refunded_amount',
        sorter: true,
        render: (value: number) => numeral(value).format(CURRENCY_FORMAT),
        ...(mounted && getColumnNumberSearch('refunded amount')),
        width: 120,
      },
      ...(dbUser?.userPermissions.ManageAssignments
        ? [
            {
              title: FIELDS_NAMES.ACTIONS,
              dataIndex: 'actions',
              render: (_: any, r: any) => (
                <Link to={routes.ASSIGNMENTS + '/' + r.assignment_id}>
                  Edit
                </Link>
              ),
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignmentStatuses,
    assignmentStatusesError,
    paymentProcessors,
    paymentProcessorsError,
    dbUser?.isCorporateAdmin,
    mounted,
  ]);

  if (
    corpClientsLoading ||
    paymentProcessorLoading ||
    assignmentStatusesLoading
  ) {
    return <Loader />;
  }

  if (
    !dbUser?.userPermissions.ViewAssignments &&
    !dbUser?.userPermissions.ManageAssignments
  ) {
    return <PermissionsAlert />;
  }

  return (
    <CCCTable
      ref={setTableRef}
      rowKey="assignment_id"
      query={QUERY_ASSIGNMENTS}
      queryKey="listAssignment"
      where={where}
      columns={columns.filter(
        (column) => !hiddenColumns?.includes(column.title),
      )}
    />
  );
};

export default AssignmentTable;
