import React, { useCallback, useContext, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import ROUTES from 'constants/routes';

import auth from 'services/Auth/Firebase';
import { AuthContext } from 'contexts/AuthContext';

import '../Auth.sass';
import { Link, useNavigate } from 'react-router-dom';

const SignUpPage = () => (
  <div className="auth-wrapper">
    <img
      className="auth-wrapper__logo"
      src="/images/Usefull_logo.png"
      alt="Usefull logo"
    />
    <div className="auth-wrapper__title">Sign up:</div>
    <SignUpForm />
    <div className="auth-wrapper__subtitle">
      Already a member?
      <Link to={ROUTES.SIGN_IN}> Sign In</Link>
    </div>
  </div>
);

const SignUpForm = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
  }, [navigate, isSignedIn]);

  const handleSubmit = useCallback(
    async (form: any) => {
      try {
        const values = form.validateFields();
        await auth.createUserWithEmailAndPassword(
          values.email,
          values.password,
        );

        navigate(ROUTES.MAIN);
      } catch (e) {
        console.log('ERROR > ', e);
      }
    },
    [navigate],
  );

  return (
    <Form layout="vertical" onFinish={handleSubmit} className="signup-form">
      <Form.Item
        name="email"
        label="E-Mail"
        rules={[{ required: true, message: 'Please input your E-Mail!' }]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please input your First Name!' }]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please input your Last Name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="passwordRepeat"
        label="Password Confirm"
        rules={[
          {
            required: true,
            message: 'Please input your Password Confirm!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="button-primary">
        Sign Up
      </Button>
    </Form>
  );
};

export default SignUpPage;

export { SignUpForm };
