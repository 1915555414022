import gql from 'graphql-tag';

export const QUERY_LIST_LOCATIONS = gql`
  query ListLocations(
    $pagination: PaginationInput
    $order: [OrderInput!]
    $where: WhereLocationInput
  ) {
    listLocation(
      pagination: $pagination
      where: $where
      order: $order
      isAdmin: true
    ) {
      total
      list {
        location_id
        name
        pretty_name

        address_line_1
        address_line_2
        city
        state
        country
        low_cup_threshold
        track_inventory

        google_place_id

        corporateClient {
          corporate_client_id
          name
        }

        capabilities {
          capability_id
          name
        }

        status {
          name
        }

        readyForCheckoutCupsAmount
        binsAmount

        last_delivered_on
        last_delivered_cups
      }
    }
  }
`;

export const QUERY_GET_LOCATION = gql`
  query GetLocation($location_id: ID!) {
    getLocation(location_id: $location_id) {
      location_id
      name
      pretty_name
      status_id
      corporate_client_id
      low_cup_threshold
      track_inventory
      menu_url
      timezone

      address_line_1
      address_line_2
      city
      state
      country

      lat
      lng

      google_place_id

      checkedOutCupsAmount
      last_delivered_cups
      last_delivered_on

      phone_number
      opening_hours {
        weekday_text
        periods {
          close {
            day
            time
            hours
            minutes
          }
          open {
            day
            time
            hours
            minutes
          }
        }
      }

      capabilities {
        capability_id
        name
      }
    }
  }
`;

export const MUTATION_CREATE_LOCATION = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(input: $input) {
      location_id
    }
  }
`;

export const MUTATION_SYNC_LOCATION_CAPABILITY = gql`
  mutation SyncLocationCapability(
    $location_id: ID!
    $input: [LocationCapabilityInput!]!
  ) {
    syncLocationCapability(location_id: $location_id, input: $input) {
      location_id
      capability_id
    }
  }
`;

export const MUTATION_UPDATE_LOCATION = gql`
  mutation UpdateLocation($location_id: ID!, $input: LocationInput!) {
    updateLocation(location_id: $location_id, input: $input) {
      location_id
    }
  }
`;

export const QUERY_LIST_LOCATION_ROLES = gql`
  query ListLocationRoles(
    $where: WhereLocationRoleInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listLocationRole(where: $where, pagination: $pagination, order: $order) {
      total
      list {
        location_role_id
        location_id
        role_id
        user_id

        assigned_by_id
        disabled_by_id

        location {
          location_id
          name
        }

        role {
          name
        }

        user {
          full_name
        }

        assigned_on
        assigned_by {
          full_name
        }

        disabled_on
        disabled_by {
          full_name
        }
      }
    }
  }
`;

export const MUTATION_CREATE_LOCATION_ROLE = gql`
  mutation CreateLocationRole($input: LocationRoleInput!) {
    createLocationRole(input: $input) {
      location_role_id

      location_id
      role_id
      user_id
    }
  }
`;

export const MUTATION_UPDATE_LOCATION_ROLE = gql`
  mutation UpdateLocationRole(
    $location_role_id: ID!
    $input: LocationRoleInput!
  ) {
    updateLocationRole(location_role_id: $location_role_id, input: $input) {
      location_role_id
    }
  }
`;
