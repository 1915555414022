import React, { useContext, useMemo } from 'react';
import { Typography, Row, Col } from 'antd';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import CCCTable from 'components/CCCTable/CCCTable';
import { QUERY_LIST_LOAD_CONTAINER } from 'queries/LoadQueries';
import { FULL_DATE } from 'constants/dateformats';
import PermissionsAlert from '../../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../../contexts/UserContext';
import routes from '../../../../constants/routes';

const { Title } = Typography;

const BoxLoadView = () => {
  const params = useParams();
  const { dbUser } = useContext(UserContext);

  const loadContainerColumns = useMemo(
    () => [
      {
        title: 'Container Name',
        dataIndex: ['container', 'unique_name'],
        key: 'container.unique_name',
        sorter: true,
        render: (name: string, record: any) =>
          dbUser?.userPermissions.ViewCups ||
          dbUser?.userPermissions.ManageCups ? (
            <Link to={`${routes.CONTAINERS}/${record.container.container_id}`}>
              {name}
            </Link>
          ) : (
            name
          ),
      },
      {
        title: 'Current Container Status',
        dataIndex: ['container', 'status', 'name'],
        key: 'container.current_status_id',
        sorter: true,
      },
      {
        title: 'Loaded on',
        dataIndex: 'loaded_on',
        sorter: true,
        render: (date: any) => moment(date).format(FULL_DATE),
      },
      {
        title: 'Note',
        dataIndex: 'note',
        sorter: true,
      },
    ],
    [dbUser],
  );

  if (
    dbUser &&
    !dbUser?.userPermissions.ManageDeliveryBoxes &&
    !dbUser?.userPermissions.ViewDeliveryBoxes
  ) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10}>
      <Col span={24}>
        <Title level={2}>
          Load Delivery Box &quot;<b>{params.load_id}</b>&quot;
        </Title>
      </Col>

      <Col>
        <CCCTable
          rowKey="load_container_id"
          query={QUERY_LIST_LOAD_CONTAINER}
          queryKey="listBoxLoadContainer"
          scrollOffsetY={350}
          where={{ load_id: { eq: params.load_id } }}
          columns={loadContainerColumns}
        />
      </Col>
    </Row>
  );
};

export default BoxLoadView;
