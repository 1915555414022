import React, { useCallback, useContext, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_GET_BIN, QUERY_LIST_BINS, MUTATION_UPDATE_BIN } from './queries';
import routes from 'constants/routes';
import BinForm from './components/BinForm';
import { useNavigate, useParams } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';
import Loader from '../../../components/Loader/Loader';

const BinEdit = () => {
  const { dbUser } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const [updateBin, { error, loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_BIN,
    {
      refetchQueries: [
        { query: QUERY_LIST_BINS },
        { query: QUERY_GET_BIN, variables: { bin_id: params.bin_id } },
      ],
    },
  );

  const { data, loading } = useQuery(QUERY_GET_BIN, {
    variables: { bin_id: params.bin_id },
  });

  const handleSubmit = useCallback(
    async (values: any) => {
      await updateBin({
        variables: {
          bin_id: params.bin_id,
          input: {
            ...values,
            corporate_client_id: values.corporate_client_id ?? null,
          },
        },
      });
      setTimeout(() => navigate(routes.BINS), 1000);
    },
    [navigate, updateBin, params],
  );

  const bin = useMemo(
    () => ({
      ...data?.getCollectionBin,
      location_id: data?.getCollectionBin.location_id
        ? data.getCollectionBin.location_id.toString()
        : null,
      corporate_client_id: data?.getCollectionBin.corporate_client_id
        ? data.getCollectionBin.corporate_client_id.toString()
        : null,
    }),
    [data],
  );

  if (loading) {
    return <Loader />;
  }

  if (
    !dbUser?.userPermissions.ViewBins &&
    !dbUser?.userPermissions.ManageBins
  ) {
    return <PermissionsAlert />;
  }

  return (
    <Row gutter={10}>
      <Col span={24}>
        {!error ? (
          <BinForm
            loading={updateLoading}
            bin_id={params.bin_id}
            bin={bin}
            handleSubmit={handleSubmit}
          />
        ) : (
          'Error:' + error.message
        )}
      </Col>
    </Row>
  );
};

export default BinEdit;
