import gql from 'graphql-tag';

export const QUERY_ASSIGNMENT_STATUSES = gql`
  query AssignemntStatuses {
    listAssignmentStatus {
      status_id
      name
    }
  }
`;

export const QUERY_ASSIGNMENTS = gql`
  query ListAssignments(
    $where: WhereAssignmentInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listAssignment(where: $where, pagination: $pagination, order: $order) {
      total
      list {
        assignment_id

        assigned_on
        user {
          user_id
          full_name

          current_client_level {
            membershipLevel {
              name
            }
          }

          user_membership {
            to
            paymentProcessor {
              name
            }
          }
        }

        payment_processor_id

        container {
          container_id
          unique_name
          corporateClient {
            name
          }
        }

        from_location_id
        from_location {
          location_id
          pretty_name
        }

        to_location_id
        to_location {
          location_id
          pretty_name
        }

        due_on
        returned_on
        returned_by

        status {
          status_id
          name
        }

        returned_by_user {
          user_id
          full_name
          email
          first_name
          last_name
        }

        past_due
        refunded_amount
        feeLedgerRecords {
          amount
          processed_on
        }
      }
    }
  }
`;

export const QUERY_ONE_ASSIGNMENT = gql`
  query OneAssignment($assignment_id: ID!) {
    getAssignment(assignment_id: $assignment_id) {
      assignment_id

      assigned_on
      returned_on
      due_on
      note
      refunded_amount
      container_id
      container {
        container_id
        unique_name
        corporateClient {
          name
          corporate_client_id
        }
      }

      returned_by
      status_id
      status {
        name
      }

      user_id
      user {
        user_id
        full_name
        email
        stripe_payment_token

        current_client_level {
          membershipLevel {
            name
          }
        }

        payment_type
        payment_promo_code

        user_membership {
          to
          payment_processor_id
          paymentProcessor {
            name
          }
        }
      }

      returned_by_user {
        user_id
        full_name
        email
        first_name
        last_name
      }

      payment_processor_id

      from_location_id
      from_location {
        pretty_name
        name
      }

      to_location_id
      to_location {
        pretty_name
        name
      }

      feeLedgerRecords {
        processed_on
        amount
        fee_transactions {
          record_id
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_ASSIGNMENT = gql`
  mutation UpdateAssignment($assignment_id: ID!, $input: AssignmentInput!) {
    updateAssignment(assignment_id: $assignment_id, input: $input) {
      assignment_id
    }
  }
`;

export const MUTATION_REMOVE_ASSIGNMENT = gql`
  mutation RemoveAssignmentWithoutFees($assignment_id: ID!) {
    removeAssignmentWithoutFees(assignment_id: $assignment_id) {
      success
      error_message {
        message
      }
    }
  }
`;

export const MUTATION_REFUND_ASSIGNMENT = gql`
  mutation RefundAssignment(
    $assignment_id: ID!
    $processor: String!
    $amount: Float!
    $formattedAmount: String!
  ) {
    refundAssignment(
      assignment_id: $assignment_id
      processor: $processor
      amount: $amount
      formattedAmount: $formattedAmount
    )
  }
`;
