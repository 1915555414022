import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_LOCATION_STATUSES } from 'queries/DictionariesQueries';

const { Option } = Select;

const LocationStatusSelect = forwardRef(({ ...props }: any, ref: any) => {
  const { data, error } = useQuery(QUERY_LIST_LOCATION_STATUSES);

  return (
    <Select ref={ref} style={{ width: '100%' }} {...props}>
      {data && !error
        ? data.listLocationStatus.map((l: any) => (
            <Option key={l.status_id} value={`${l.status_id}`}>
              {l.name}
            </Option>
          ))
        : null}
    </Select>
  );
});

LocationStatusSelect.displayName = 'LocationStatusSelect';

export default LocationStatusSelect;
