import React, { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import {
  MUTATION_CREATE_LOCATION,
  MUTATION_SYNC_LOCATION_CAPABILITY,
} from './queries';
import LocationForm from './components/LocationForm';
import routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import { UserContext } from '../../../contexts/UserContext';

const LocationCreate = () => {
  const navigate = useNavigate();
  const { dbUser } = useContext(UserContext);
  const [createLocation] = useMutation(MUTATION_CREATE_LOCATION);
  const [syncLocationCapability] = useMutation(
    MUTATION_SYNC_LOCATION_CAPABILITY,
  );
  const submit = useCallback(
    async ({ capabilities, ...input }: any) => {
      const {
        data: {
          createLocation: { location_id },
        },
      }: any = await createLocation({
        variables: {
          input,
        },
      });

      if (capabilities) {
        await syncLocationCapability({
          variables: {
            location_id,
            input: capabilities?.map((capability_id: string) => ({
              capability_id,
              location_id,
            })),
          },
        });
      }
      navigate(routes.LOCATIONS);
    },
    [navigate, createLocation, syncLocationCapability],
  );

  if (dbUser && !dbUser.userPermissions.ManageLocations) {
    return <PermissionsAlert />;
  }

  return <LocationForm handleSubmit={submit} />;
};

export default LocationCreate;
