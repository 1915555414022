import React, { useMemo, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment/moment';
import { Modal, Table, Button } from 'antd';

import { FIELDS_NAMES } from './const';
import numeral from 'numeral';
import { CURRENCY_FORMAT } from '../../constants/numberformats';

interface IFeeTransactionsTableProps {
  data: any;
}
const FeeTransactionsTable = ({ data }: IFeeTransactionsTableProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentResponse, setCurrentResponse] = useState('');

  const showModal = (response: string) => {
    setIsModalOpen(true);
    setCurrentResponse(JSON.stringify(JSON.parse(response), null, 2));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrentResponse('');
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setCurrentResponse('');
  };

  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: FIELDS_NAMES.DATE,
        key: 'date',
        dataIndex: 'date',
        sorter: (recordA: { date: string }, recordB: { date: string }) =>
          Number(new Date(recordA.date)) - Number(new Date(recordB.date)),

        render: (data: string) =>
          data ? moment(data).format('LLL') : '- not set -',
      },
      {
        title: FIELDS_NAMES.TOTAL_AMOUNT,
        key: 'total amount',
        dataIndex: 'amount',
        sorter: (recordA: { amount: number }, recordB: { amount: number }) =>
          recordA.amount - recordB.amount,
        render: (amount: number) => numeral(amount).format(CURRENCY_FORMAT),
      },
      {
        title: FIELDS_NAMES.AMOUNT_CHARGED,
        key: 'amount charged',
        dataIndex: 'is_successful',
        sorter: (
          recordA: { amount: number; is_successful: boolean },
          recordB: { amount: number; is_successful: boolean },
        ) =>
          (recordA.is_successful ? recordA.amount : 0) -
          (recordB.is_successful ? recordB.amount : 0),
        render: (is_successful: boolean, record: { amount: number }) =>
          numeral(is_successful ? record.amount : 0).format(CURRENCY_FORMAT),
      },
      {
        title: FIELDS_NAMES.SUCCESS,
        key: 'success',
        dataIndex: 'is_successful',
        sorter: (recordA: any, recordB: any) =>
          (recordA.is_successful ? 'Yes' : 'No').localeCompare(
            recordB.is_successful ? 'Yes' : 'No',
          ),
        render: (is_successful: boolean) => (is_successful ? 'Yes' : 'No'),
      },
      {
        title: FIELDS_NAMES.RESPONSE,
        key: 'response',
        dataIndex: 'response',
        width: 300,
        render: (response: string) => (
          <div>
            <span>{response.slice(0, 20)}...</span>
            <Button type="link" onClick={() => showModal(response)}>
              View Details
            </Button>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <>
      <Modal
        title="Response modal"
        open={isModalOpen}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <pre>{currentResponse}</pre>
      </Modal>
      <Table
        tableLayout="fixed"
        rowKey={'record_id'}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default FeeTransactionsTable;
