import React from 'react';
import { Avatar, Menu, Dropdown, Divider } from 'antd';
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import auth from '../../services/Auth/Firebase';

import 'antd/dist/antd.min.css';
import './Header.sass';

interface HeaderPropsInterface {
  isSidebarCollapsed: boolean;
  toggle(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
  user?: { email: string; first_name: string; last_name: string };
}

const UserInfo = ({ currentUser }: any) => (
  <div className="header__dropdown__user-info">
    <div className="user-name">
      {currentUser
        ? `${currentUser.first_name} ${currentUser.last_name}`
        : 'loading...'}
    </div>
    <div>{currentUser ? currentUser.email : null}</div>
  </div>
);

const Header = ({ isSidebarCollapsed, toggle, user }: HeaderPropsInterface) => {
  const menu = (
    <Menu className="header__dropdown">
      <Menu.Item key="avatar">
        <UserInfo currentUser={user} />
      </Menu.Item>
      <Menu.Item key="divider">
        <Divider />
      </Menu.Item>
      <Menu.Item key="sign out link">
        <a
          rel="noopener noreferrer"
          href="#/"
          onClick={async (e) => {
            e.preventDefault();
            await auth.signOut();
          }}
        >
          Sign Out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header">
      <Dropdown overlay={menu} trigger={['click']}>
        <Avatar size={50} icon={<UserOutlined />} />
      </Dropdown>
      {isSidebarCollapsed ? (
        <MenuUnfoldOutlined
          className="header__sidebar-trigger"
          onClick={toggle}
        />
      ) : (
        <MenuFoldOutlined
          className="header__sidebar-trigger"
          onClick={toggle}
        />
      )}
    </div>
  );
};

export default Header;
