const options = [
  {
    label: 'XLSX',
    key: 'xlsx',
  },
  {
    label: 'PDF',
    key: 'pdf',
  },
];

export default options;
