import React from 'react';

import './Story.sass';

interface InstagramStoryProps {
  date: string;
  containers: number;
  wasteAvoided: number;
  waterSaved: number;
  co2Saved: number;
}

const getImpactValue = (value: number | undefined) =>
  value && value > 0 ? value.toFixed(1) : 0;

export const Story = ({
  date,
  containers,
  wasteAvoided,
  co2Saved,
  waterSaved,
}: InstagramStoryProps) => {
  return (
    <div className="story">
      <div className="story-content">
        <div className="story-content-top">
          <h2 className="story-title">Environmental impact through today</h2>
          <h3 className="story-date">{date}</h3>
          <p className="story-count">{containers}</p>
          <img
            alt="usefull logo"
            src="/images/Usefull_logo.png"
            className="story-usefull-logo"
          />
          <p className="story-containers">Reusable containers checked out</p>
        </div>

        <h2 className="story-stats-title">Together we have saved:</h2>

        <div className="story-post-stats">
          <div className="story-post-stats-item">
            <img
              alt="trash"
              src="/images/trash-truck.png"
              width={226}
              height={160}
              className="story-post-image"
            />
            <span className="story-post-stats-value">
              {getImpactValue(wasteAvoided)} LBS
            </span>
            <b className="story-post-stats-label">of trash</b>
          </div>

          <div className="story-post-stats-item">
            <img
              alt="water"
              src="/images/shower-water.png"
              width={160}
              height={160}
              className="story-post-image"
            />
            <span className="story-post-stats-value">
              {getImpactValue(waterSaved)} GAL
            </span>
            <b className="story-post-stats-label">of water</b>
          </div>

          <div className="story-post-stats-item">
            <img
              alt="global-warming"
              src="/images/global-warming.png"
              width={160}
              height={160}
              className="story-post-image"
            />
            <span className="story-post-stats-value">
              {getImpactValue(co2Saved)} LBS
            </span>
            <b className="story-post-stats-label">of emissions</b>
          </div>
        </div>
      </div>

      <div className="story-footer">
        <p className="story-description">
          Thank you for doing your part
          <br />
          to help our community and the planet!
        </p>
      </div>
    </div>
  );
};
