import React, { useContext } from 'react';
import CorporateClientsTable from 'components/Tables/CorporateClientsTable';
import { UserContext } from '../../../contexts/UserContext';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';

const Index = () => {
  const { dbUser } = useContext(UserContext);

  if (dbUser && !dbUser.userPermissions.ManageCorporateClients) {
    return <PermissionsAlert />;
  }

  return (
    <CorporateClientsTable
      where={
        dbUser?.isCorporateAdmin
          ? {
              corporate_client_id: {
                in: dbUser?.userCorporateClients?.map(
                  (uc) => uc.corporate_client_id,
                ),
              },
            }
          : undefined
      }
    />
  );
};

export default Index;
