import React, { useContext, useMemo } from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import isMobile from 'is-mobile';

import './Sidebar.sass';
import ROUTES from '../../constants/routes';
import { UserContext } from '../../contexts/UserContext';

const { Sider } = Layout;

const KNOWLEDGE_BASE_URL = 'https://kb.usefull.us/knowledgebase';
const HELPDESK_URL = 'https://kb.usefull.us/knowledgebase/kb-tickets/new';

interface ISidebar {
  collapsed: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];

const Sidebar = ({ collapsed }: ISidebar) => {
  const location = useLocation();

  const selectedKeys = [];
  for (const key in ROUTES) {
    const routePath = (ROUTES as any)[key];
    if (location.pathname.startsWith(routePath)) {
      selectedKeys.push(routePath);
    }
  }
  const mobile = useMemo(() => isMobile(), []);

  const { dbUser } = useContext(UserContext);

  const menuItems: MenuItem[] = [
    {
      key: 'main',
      label:
        dbUser?.userPermissions.ManageUsers ||
        dbUser?.userPermissions.ManageLocations ||
        dbUser?.userPermissions.ViewLocations ||
        dbUser?.userPermissions.ManageAssignments ||
        dbUser?.userPermissions.ViewAssignments
          ? 'Main'
          : '',
      type: 'group',
      children: [
        {
          key: ROUTES.MAIN,
          label: <Link to={ROUTES.MAIN}>Dashboard</Link>,
        },
        ...(dbUser?.userPermissions.ManageUsers
          ? [
              {
                key: ROUTES.USERS,
                label: <Link to={ROUTES.USERS}>Users</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageLocations ||
        dbUser?.userPermissions.ViewLocations
          ? [
              {
                key: ROUTES.LOCATIONS,
                label: <Link to={ROUTES.LOCATIONS}>Locations</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageAssignments ||
        dbUser?.userPermissions.ViewAssignments
          ? [
              {
                key: ROUTES.ASSIGNMENTS,
                label: <Link to={ROUTES.ASSIGNMENTS}>Assignments</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageCorporateClients
          ? [
              {
                key: ROUTES.CORPORATE_CLIENTS,
                label: <Link to={ROUTES.CORPORATE_CLIENTS}>Clients</Link>,
              },
            ]
          : []),
        ...(!dbUser?.isCorporateAdmin
          ? [
              {
                key: ROUTES.TV_HOSTED_PAGE,
                label: (
                  <Link to={ROUTES.TV_HOSTED_PAGE + '?admin=true'}>
                    Master Impact Dashboard
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'inventory',
      label:
        dbUser?.userPermissions.ManageCups ||
        dbUser?.userPermissions.ViewCups ||
        dbUser?.userPermissions.ManageDeliveryBoxes ||
        dbUser?.userPermissions.ViewDeliveryBoxes ||
        dbUser?.userPermissions.ManageBins ||
        dbUser?.userPermissions.ViewBins
          ? 'Inventory'
          : '',
      type: 'group',
      children: [
        ...(dbUser?.userPermissions.ManageCups ||
        dbUser?.userPermissions.ViewCups
          ? [
              {
                key: ROUTES.CONTAINERS,
                label: <Link to={ROUTES.CONTAINERS}>Containers</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageDeliveryBoxes ||
        dbUser?.userPermissions.ViewDeliveryBoxes
          ? [
              {
                key: ROUTES.BOXES,
                label: <Link to={ROUTES.BOXES}>Delivery Boxes</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageBins ||
        dbUser?.userPermissions.ViewBins
          ? [
              {
                key: ROUTES.BINS,
                label: <Link to={ROUTES.BINS}>Return Bins</Link>,
              },
            ]
          : []),
      ],
    },
    {
      type: 'divider',
    },
    ...(dbUser?.userPermissions.BasicReports ||
    dbUser?.userPermissions.AdminReports
      ? [
          {
            key: ROUTES.REPORTS_DASHBOARD,
            label: <Link to={ROUTES.REPORTS_DASHBOARD}>Reports</Link>,
          },
        ]
      : []),
    {
      key: 'help',
      label: 'Help',
      children: [
        {
          key: 'knowledge_base',
          label: (
            <a href={KNOWLEDGE_BASE_URL} target="_blank" rel="noreferrer">
              USEFULL Knowledge Base
            </a>
          ),
        },
        {
          key: 'helpdesk',
          label: (
            <a href={HELPDESK_URL} target="_blank" rel="noreferrer">
              USEFULL Helpdesk
            </a>
          ),
        },
      ],
    },
    ...(dbUser?.userPermissions.ManageSettings
      ? ([
          {
            type: 'divider',
          },
          {
            key: 'settings',
            label: 'Settings',
            children: [
              {
                key: ROUTES.MEMBERSHIPS,
                label: <Link to={ROUTES.MEMBERSHIPS}>Subscriptions</Link>,
              },
              {
                key: ROUTES.TOAST_MESSAGES,
                label: <Link to={ROUTES.TOAST_MESSAGES}>Toast Messages</Link>,
              },
            ],
          },
        ] as MenuItem[])
      : []),
  ];

  const menu = dbUser ? (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={selectedKeys}
      items={menuItems}
    />
  ) : null;

  return (
    <Sider
      className="sidebar"
      trigger={null}
      collapsible
      collapsed={mobile && collapsed}
    >
      <img
        className="sidebar__logo"
        src="/images/Usefull_logo.png"
        alt="USEFULL logo"
      />
      {menu}
    </Sider>
  );
};

export default Sidebar;
