import gql from 'graphql-tag';

export const QUERY_USER_FEE_TRANSACTIONS = gql`
  query GetFeeTransactions($user_id: ID!) {
    getFeeTransactions(user_id: $user_id) {
      record_id
      user_id
      amount
      date
      is_successful
      response
    }
  }
`;
