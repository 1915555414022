import { useCallback, useMemo, useRef, useState } from 'react';

const useTable = () => {
  const tableRef = useRef<any>(null);
  const [mounted, setMounted] = useState(false);

  const setTableRef = useCallback(
    (node: any) => {
      tableRef.current = node;
      setMounted(true);
    },
    [setMounted],
  );

  const getColumnSearchProps = useCallback((name: string) => {
    if (tableRef.current && tableRef.current.getColumnSearchProps) {
      return tableRef.current.getColumnSearchProps(name);
    }
    return {};
  }, []);

  const getColumnNumberSearch = useCallback((name: string) => {
    if (tableRef.current && tableRef.current.getColumnNumberSearch) {
      return tableRef.current.getColumnNumberSearch(name);
    }
    return {};
  }, []);

  const getColumnRadioFilterProps = useCallback((name: string) => {
    if (tableRef.current && tableRef.current.getColumnNumberSearch) {
      return tableRef.current.getColumnRadioFilterProps(name);
    }
    return {};
  }, []);

  const setSelectedRowKeys = useCallback((value: Array<string>) => {
    if (tableRef.current && tableRef.current.setSelectedRowKeys) {
      tableRef.current.setSelectedRowKeys(value);
    }
  }, []);

  const refetch = useCallback(() => {
    if (tableRef.current && tableRef.current.refetch) {
      tableRef.current.refetch();
    }
  }, []);

  return useMemo(
    () => ({
      mounted,
      setTableRef,
      refetch,
      getColumnSearchProps,
      getColumnNumberSearch,
      getColumnRadioFilterProps,
      setSelectedRowKeys,
    }),
    [
      mounted,
      setTableRef,
      refetch,
      getColumnSearchProps,
      getColumnNumberSearch,
      getColumnRadioFilterProps,
      setSelectedRowKeys,
    ],
  );
};

export default useTable;
