import gql from 'graphql-tag';

export const QUERY_LIST_LOAD = gql`
  query ListBoxLoad(
    $where: WhereBoxLoadInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listBoxLoad(where: $where, pagination: $pagination, order: $order) {
      total
      list {
        load_id
        box_id

        started_on
      }
    }
  }
`;

export const QUERY_LIST_LOAD_CONTAINER = gql`
  query ListLoadContainer(
    $where: WhereBoxLoadContainerInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listBoxLoadContainer(
      where: $where
      pagination: $pagination
      order: $order
    ) {
      total

      list {
        load_container_id
        load_id
        loaded_on

        note

        container {
          container_id
          unique_name
          status {
            name
          }
        }
      }
    }
  }
`;
