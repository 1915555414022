import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './fonts/Merriweather/Merriweather-Bold.ttf';

import './fonts/ProximaNova/ProximaNova-Bold.ttf';
import './fonts/ProximaNova/ProximaNova-Regular.ttf';
import './fonts/ProximaNova/ProximaNova-Semibold.ttf';

import './styles/index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
