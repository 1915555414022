import React, { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  QUERY_GET_USER,
  QUERY_LIST_USERS,
  MUTATION_UPDATE_USER,
  MUTATION_UPDATE_NOTIFICATION_PREFERENCES,
  QUERY_GET_NOTIFICATION_PREFERENCES,
} from '../../../queries/UserQueries';
import UserForm from './components/UserForm';
import routes from 'constants/routes';
import { Tabs, Button, Col, Popconfirm, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import {
  MUTATION_UPDATE_LOCATION_ROLE,
  QUERY_LIST_LOCATION_ROLES,
} from '../Locations/queries';
import moment from 'moment';
import { FULL_DATE } from '../../../constants/dateformats';
import stripTypename from '../../../services/stripTypename';
import { UserContext } from '../../../contexts/UserContext';
import AssignmentTable from '../../../components/Tables/AssignmentTable';
import { FIELDS_NAMES } from 'components/Tables/const';
import UserPreferencesForm from './components/UserPreferencesForm';
import DangerZone from './components/DangerZone';
import { QUERY_USER_FEE_LEDGER } from '../../../queries/FeeLedgerQueries';
import FeeLedgerTable from '../../../components/Tables/FeeLedgerTable';
import FeeTransactionsTable from '../../../components/Tables/FeeTransactionsTable';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';
import { QUERY_USER_FEE_TRANSACTIONS } from '../../../queries/FeeTransactionsQueries';

const { TabPane } = Tabs;

const UsersEdit = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const { dbUser } = useContext(UserContext);
  const { setTableRef, refetch } = useTable();

  const {
    data,
    loading,
    error,
    refetch: refetchUser,
  } = useQuery(QUERY_GET_USER, {
    variables: { user_id },
  });

  const { data: feeLedgerData, error: feeLedgerError } = useQuery(
    QUERY_USER_FEE_LEDGER,
    {
      variables: {
        user_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );

  const { data: feeTransactionsData, error: feeTransactionsError } = useQuery(
    QUERY_USER_FEE_TRANSACTIONS,
    {
      variables: {
        user_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );

  const {
    data: dataNotificationPreferences,
    loading: loadingNotificationPreferences,
    error: errorNotificationPreferences,
  } = useQuery(QUERY_GET_NOTIFICATION_PREFERENCES, {
    variables: { user_id },
  });
  const [updateUser] = useMutation(MUTATION_UPDATE_USER);
  const [updateLocationRole] = useMutation(MUTATION_UPDATE_LOCATION_ROLE);
  const [updateNotificationPreferences] = useMutation(
    MUTATION_UPDATE_NOTIFICATION_PREFERENCES,
  );
  const submit = useCallback(
    async ({ ...input }: any) => {
      await updateUser({
        variables: {
          user_id,
          input,
        },
        refetchQueries: [
          {
            query: QUERY_GET_USER,
            variables: {
              user_id,
            },
          },
          {
            query: QUERY_LIST_USERS,
          },
        ],
      });
      navigate(routes.USERS);
    },
    [navigate, updateUser, user_id],
  );
  const submitNotificationPreferences = useCallback(
    async (prefs: string) => {
      await updateNotificationPreferences({
        variables: {
          user_id,
          input: {
            prefs,
          },
        },
      });
    },
    [navigate, user_id],
  );

  const locationRoleColumns: any[] = useMemo(
    () => [
      {
        title: 'Location',
        dataIndex: ['location', 'name'],
        render: (loc_name: string) => {
          return loc_name || '- All -';
        },
      },
      {
        title: 'Full Name',
        dataIndex: ['user', 'full_name'],
      },
      {
        title: 'Role',
        dataIndex: ['role', 'name'],
      },
      {
        title: 'Assigned By',
        dataIndex: ['assigned_by', 'full_name'],
      },
      {
        title: 'Assigned On',
        dataIndex: 'assigned_on',
        render: (assigned_on: string) =>
          assigned_on ? moment(assigned_on).format(FULL_DATE) : null,
      },

      {
        title: 'Disabled On',
        dataIndex: 'disabled_on',
        render: (disabled_on: string) =>
          disabled_on ? moment(disabled_on).format(FULL_DATE) : null,
        width: 120,
      },
      ...(dbUser?.userPermissions.ManageLocations
        ? [
            {
              title: 'Actions',
              key: 'actions',
              render: (record: any) => (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={async () => {
                    const input: any = {};
                    for (const key in record) {
                      if (typeof record[key] !== 'object') {
                        input[key] = record[key];
                      }
                    }
                    delete input.location_role_id;

                    await updateLocationRole({
                      variables: {
                        location_role_id: record.location_role_id,
                        input: {
                          ...stripTypename(input),
                          ...(!record.disabled_on
                            ? {
                                disabled_on: new Date(),
                                disabled_by_id: dbUser ? dbUser.user_id : null,
                              }
                            : {
                                disabled_on: null,
                                disabled_by_id: null,
                              }),
                        },
                      },
                    });
                    refetch();
                  }}
                >
                  <a href="/#">{record.disabled_on ? 'Activate' : 'Disable'}</a>
                </Popconfirm>
              ),
            },
          ]
        : []),
    ],
    [dbUser],
  );

  if (loading) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ManageUsers) {
    return <PermissionsAlert />;
  }

  return (
    <Row>
      <Row align={'middle'} justify={'space-between'}>
        <Col span={12}>
          {!loading && !error ? (
            <UserForm
              user={data.getUser}
              handleSubmit={submit}
              refetchUser={refetchUser}
            />
          ) : error ? (
            'Error:' + error.message
          ) : (
            'loading...'
          )}
        </Col>
        {dbUser?.userPermissions.DangerZone &&
          data?.getUser.firebase_user_id &&
          !data?.getUser.email.includes('DELETED') && (
            <Col span={11}>
              <DangerZone user={data?.getUser} />
            </Col>
          )}
      </Row>
      <Col span={24} style={{ marginTop: 50 }}>
        <Tabs
          tabBarExtraContent={
            dbUser?.userPermissions.ManageLocations
              ? {
                  right: (
                    <Link
                      to={'/locations/role/create'}
                      state={{
                        user: data ? data.getUser : { user_id },
                        backTo: routes.USERS + '/' + user_id,
                      }}
                    >
                      <Button type="primary">Create Location Role</Button>
                    </Link>
                  ),
                }
              : null
          }
        >
          <TabPane tab="Location Roles" key="1">
            <CCCTable
              ref={setTableRef}
              rowKey="location_role_id"
              query={QUERY_LIST_LOCATION_ROLES}
              queryKey="listLocationRole"
              columns={locationRoleColumns}
              where={{ user_id: { eq: user_id } }}
            />
          </TabPane>
          <TabPane tab="User's Assignments" key="2">
            <AssignmentTable
              where={{ user_id: { eq: user_id } }}
              hiddenColumns={[FIELDS_NAMES.USER]}
            />
          </TabPane>
          <TabPane tab="Notifications Preferences" key="3">
            {!loadingNotificationPreferences &&
            !errorNotificationPreferences ? (
              <UserPreferencesForm
                data={dataNotificationPreferences.getNotificationPreferences}
                handleSubmit={submitNotificationPreferences}
              />
            ) : errorNotificationPreferences ? (
              'Error:' + errorNotificationPreferences.message
            ) : (
              'loading...'
            )}
          </TabPane>
          <TabPane tab="Fee Ledger" key="4">
            {feeLedgerData && !feeLedgerError && (
              <FeeLedgerTable
                data={feeLedgerData?.getFeeLedgerByUser}
                query={QUERY_USER_FEE_LEDGER}
                isAssignmentIdShown
              />
            )}
          </TabPane>
          <TabPane tab="Transactions" key="5">
            {feeTransactionsData && !feeTransactionsError && (
              <FeeTransactionsTable
                data={feeTransactionsData?.getFeeTransactions}
              />
            )}
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default UsersEdit;
