import React, { useEffect } from 'react';
import './VerifyEmail.sass';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/client';

import { MUTATION_VERIFY_EMAIL } from '../../queries/UserQueries';

const VerifyEmail = () => {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const token = searchQuery.get('tokenKey') || '';

  const [verifyEmail, { data, loading }] = useMutation(MUTATION_VERIFY_EMAIL);

  useEffect(() => {
    verifyEmail({
      variables: {
        token,
      },
    });
  }, [token]);

  const content = data?.verifyEmail ? (
    <div className="verify-email-modal">
      <CheckCircleOutlined className="verify-email-icon verify-email-icon-success" />

      <h2 className="verify-email-title">Email Verified</h2>
      <p className="verify-email-subtitle">
        Your email has been verified successfully
      </p>
    </div>
  ) : (
    <div className="verify-email-modal">
      <CloseCircleOutlined className="verify-email-icon verify-email-icon-fail" />

      <h2 className="verify-email-title">Email Not Verified</h2>
    </div>
  );

  return (
    <div className="verify-email-container">{loading ? null : content}</div>
  );
};

export default VerifyEmail;
