import React, { useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { AuthContext, AuthProvider } from './contexts/AuthContext';
import ApolloProvider from './services/ApolloProvider';
import { UserContext, UserProvider } from './contexts/UserContext';

import ROUTES from './constants/routes';

import 'antd/dist/antd.min.css';

import Dashboard from './screens/Dashboard/Index';

import SignUp from './screens/Auth/SignUp/SignUp';
import SignIn from './screens/Auth/SignIn/SignIn';
import Forbidden from './screens/Auth/403';
import VerifyEmail from './screens/VerifyEmail/VerifyEmail';
import { CorpClientHostedPage } from './screens/CorpClientHostedPage/CorpClientHostedPage';
import { SharebleHostedPage } from 'screens/ShareableHostedPage/SharebleHostedPage';

function PrivateOutlet() {
  const location = useLocation();
  const { isSignedIn, loading } = useContext(AuthContext);
  const { dbUser } = useContext(UserContext);

  return loading ? null : dbUser && !dbUser.permissions?.length ? (
    <Navigate to={ROUTES.FORBIDDEN} state={{ from: location.pathname }} />
  ) : !isSignedIn ? (
    <Navigate to={ROUTES.SIGN_IN} state={{ from: location.pathname }} />
  ) : (
    <Dashboard />
  );
}

const App = () => {
  return (
    <AuthProvider>
      <ApolloProvider>
        <UserProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
              <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
              <Route path={ROUTES.FORBIDDEN} element={<Forbidden />} />
              <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
              <Route
                path={ROUTES.TV_HOSTED_PAGE}
                element={<CorpClientHostedPage />}
              />
              <Route
                path={ROUTES.TV_HOSTED_PAGE + '/:ccUuid'}
                element={<CorpClientHostedPage />}
              />
              <Route
                path={ROUTES.TV_SHARED_PAGE}
                element={<SharebleHostedPage />}
              />
              <Route path="*" element={<PrivateOutlet />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
