import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';

import routes from 'constants/routes';

import { QUERY_LIST_BOXES, QUERY_LOCATIONS } from 'queries/BoxQueries';
import { QUERY_LIST_CORPORATE_CLIENTS } from '../../../queries/DictionariesQueries';
import { UserContext } from '../../../contexts/UserContext';
import printOptions from '../../../constants/PrintOptions';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const Index = () => {
  const { mounted, setTableRef, getColumnSearchProps } = useTable();
  const { dbUser } = useContext(UserContext);

  const {
    data: locations,
    error: locationsError,
    loading: locationsLoading,
  } = useQuery(QUERY_LOCATIONS);
  const {
    data: corpClients,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      order: [{ field: 'name', order: 'ASC' }],
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'unique_name',
        render: (name: string, record: any) => (
          <Link to={routes.BOXES + '/' + record.box_id}>{name}</Link>
        ),
        sorter: true,
        filterOperation: 'contains',

        ...(mounted && getColumnSearchProps('unique_name')),
        width: 180,
      },
      {
        title: 'Corporate Client',
        dataIndex: ['corporateClient', 'name'],
        key: 'corporate_client_id',
        sorter: true,
        filters:
          corpClients && !corpClientsError
            ? [
                ...corpClients.listCorporateClient.map((l: any) => ({
                  value: l.corporate_client_id,
                  text: l.name,
                })),
                ...(dbUser?.isCorporateAdmin
                  ? []
                  : [
                      {
                        value: null,
                        text: 'NOT ASSIGNED',
                      },
                    ]),
              ]
            : [],
        width: 180,
      },
      {
        title: '# Loads',
        dataIndex: 'boxLoads',
        render: (value: Array<Record<string, any>>) => value?.length,
        width: 100,
      },
      {
        title: '# Deliveries',
        dataIndex: 'boxLoads',
        render: (value: Array<Record<string, any>>) =>
          _.sumBy(value, 'deliveries.length'),
        width: 100,
      },
      {
        title: 'Last Location',
        dataIndex: 'location.name',
        key: 'last_location_id',
        sorter: true,
        render: (loc_name: string, { location }: any) =>
          location ? (
            dbUser?.userPermissions.ManageLocations ? (
              <Link
                to={routes.LOCATIONS + '/' + location.location_id + '/edit'}
              >
                {location.name}
              </Link>
            ) : (
              location.name
            )
          ) : null,
        filters:
          locations && !locationsError
            ? locations.listLocation.list.map((l: any) => ({
                value: l.location_id,
                text: l.name,
              }))
            : [],
        width: 180,
      },
    ],
    [locations, locationsError, mounted], //eslint-disable-line
  );

  const actions = [
    {
      label: 'Print QR Codes',
      type: 'primary',
      isDropdown: true,
      dropdownItems: printOptions,
      runItem: async (records: any[], key: string) => {
        const api = process.env.REACT_APP_GRAPHQL_SERVER_URI || '';

        window.open(
          api.replace('/graphql', '') +
            `/print?type=box&format=${key}&` +
            records.map((box: any) => `id[]=${box.box_id}`).join('&'),
          '_blank',
        );
      },
    },
    {
      label: 'Create Delivery Boxes',
      type: 'primary',
      to: routes.BOXES + '/create',
    },
  ];

  if (locationsLoading || corpClientsLoading) {
    return <Loader />;
  }

  if (
    !dbUser?.userPermissions.ManageDeliveryBoxes &&
    !dbUser?.userPermissions.ViewDeliveryBoxes
  ) {
    return <PermissionsAlert />;
  }

  return (
    <CCCTable
      ref={setTableRef}
      query={QUERY_LIST_BOXES}
      queryKey="listDeliveryBox"
      rowKey="box_id"
      columns={columns}
      actions={dbUser?.userPermissions.ManageDeliveryBoxes ? actions : []}
      where={
        dbUser?.isCorporateAdmin
          ? {
              corporate_client_id: {
                in: dbUser?.userCorporateClients?.map(
                  (uc) => uc.corporate_client_id,
                ),
              },
            }
          : undefined
      }
    />
  );
};

export default Index;
