import gql from 'graphql-tag';

export const QUERY_MEMBERSHIPS = gql`
  query ListMemberships(
    $where: WhereMembershipLevelInput
    $order: [OrderInput!]
    $pagination: PaginationInput
  ) {
    listMembershipLevel(where: $where, order: $order, pagination: $pagination) {
      list {
        level_id
        name
        description
        stripe_price {
          amount
        }
        stripe_plan_id
      }
    }
  }
`;

export const QUERY_STRIPE_PLANS = gql`
  query ListStripePlans {
    listStripePlan {
      id
      amount
      nickname

      interval
      usage_type
    }
  }
`;

export const QUERY_PAYMENT_PROCESSORS = gql`
  query ListPaymentProcessors {
    listPaymentProcessor {
      processor_id
      name
    }
  }
`;
