import React, { forwardRef } from 'react';
import * as _ from 'lodash';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_STRIPE_PLANS } from '../../queries/MembershipLevel';

const { Option } = Select;

const priceFormat = (price: number) => `$` + (price / 100).toFixed(2);

const StripePlanSelect = forwardRef(
  ({ value, filter, ...props }: any, ref: any) => {
    const { data, error } = useQuery(QUERY_STRIPE_PLANS);

    return (
      <Select ref={ref} value={value} style={{ width: '100%' }} {...props}>
        {data && !error
          ? data.listStripePlan
              .filter((p: any) => (filter ? _.matches(filter)(p) : true))
              .map((p: any) => (
                <Option key={p.id} value={`${p.id}`}>
                  {p?.nickname}{' '}
                  <span style={{ color: 'gray' }}>
                    {priceFormat(p.amount)} / {p.interval}
                  </span>
                </Option>
              ))
          : null}
      </Select>
    );
  },
);

StripePlanSelect.displayName = 'StripePlanSelect';

export default StripePlanSelect;
