import gql from 'graphql-tag';

export const QUERY_LIST_TOAST_MESSAGES = gql`
  query ListToastMessage(
    $pagination: PaginationInput
    $order: [OrderInput!]
    $where: WhereToastMessageInput
  ) {
    listToastMessage(pagination: $pagination, where: $where, order: $order) {
      total
      list {
        message_id
        message
        image_url
        added_by_id
        added_on
        enabled

        added_by {
          full_name
        }
      }
    }
  }
`;

export const QUERY_GET_TOAST_MESSAGE = gql`
  query GetToastMessage($message_id: ID!) {
    getToastMessage(message_id: $message_id) {
      message_id
      message
      image_url
      added_by_id
      added_on
      enabled
    }
  }
`;

export const MUTATION_CREATE_TOAST_MESSAGE = gql`
  mutation CreateToastMessage($input: ToastMessageInput!) {
    createToastMessage(input: $input) {
      message_id
    }
  }
`;

export const MUTATION_REMOVE_TOAST_MESSAGE = gql`
  mutation RemoveToastMessage($message_id: ID!) {
    removeToastMessage(message_id: $message_id)
  }
`;

export const MUTATION_UPDATE_TOAST_MESSAGE = gql`
  mutation UpdateToastMessage($message_id: ID!, $input: ToastMessageInput!) {
    updateToastMessage(message_id: $message_id, input: $input) {
      message_id
    }
  }
`;
