import React, {
  ChangeEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Input } from 'antd';

export const EditingField = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>();

  const handleBlur = useCallback(() => {
    setEditing(false);
  }, []);

  const handleClick = useCallback(() => {
    setEditing(true);
  }, []);

  useLayoutEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, editing]);

  return (
    <div>
      {editing ? (
        <Input
          ref={inputRef}
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
        />
      ) : (
        <div onClick={handleClick}>{value}</div>
      )}
    </div>
  );
};
