import gql from 'graphql-tag';

export const CONTAINER_CHECK_IN = gql`
  mutation ContainerCheckIn(
    $to_location_id: ID!
    $container_id: ID!
    $returned_by: ID
  ) {
    containerCheckIn(
      container_id: $container_id
      to_location_id: $to_location_id
      returned_by: $returned_by
    ) {
      success
    }
  }
`;
