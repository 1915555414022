export interface AddressPropertiesType {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  country?: string;
}

const formatAddress = (props: AddressPropertiesType) => {
  return [props.address_line_1, props.address_line_2, props.city, props.state]
    .filter((v: any) => !!v)
    .join(', ');
};

export default formatAddress;
