import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import LocationSearchSelect from '../../../../components/Select/LocationSearchSelect';

const BoxForm = ({ handleSubmit, loading, box }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (box) {
      form.setFieldsValue({
        ...box,
        last_location_id: box?.last_location_id?.toString(),
      });
    }
  }, [form, box]);

  return (
    <Form
      layout="vertical"
      name="box_edit"
      form={form}
      onFinish={handleSubmit}
      initialValues={box}
    >
      <Form.Item
        label="Last location"
        name="last_location_id"
        rules={[{ required: true, message: 'Please select location!' }]}
      >
        <LocationSearchSelect placeholder="Search for Location" />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={loading}>
        Update Delivery Box
      </Button>
    </Form>
  );
};

export default BoxForm;
