import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Row, Col, Card } from 'antd';
import { useQuery } from '@apollo/client';

import StripePlanSelect from '../../../../components/Select/StripePlanSelect';
import { QUERY_STRIPE_PLANS } from '../../../../queries/MembershipLevel';

import { QUERY_LIST_CONTAINER_TYPES } from '../../../../queries/DictionariesQueries';

import '../Memberships.sass';
const { Title } = Typography;

export const MembershipContainerTypeSettingsForm = ({
  containerType,
  containerTypeIndex,
  getMeteredPrice,
  form: { getFieldValue },
}: any) => {
  const path = (field: string) => [
    'membershipContainerTypes',
    containerTypeIndex,
    field,
  ];
  const container_type_id = path('container_type_id');
  const late_fee_plan_id = path('late_fee_plan_id');
  const lost_fee_plan_id = path('lost_fee_plan_id');
  const max_cost = path('max_cost');

  const rentDurationDays = getFieldValue('rent_duration_days') || 0;
  const rentDurationDaysValue = rentDurationDays ? +rentDurationDays + 2 : 0;
  const rentDurationMaxDays = getFieldValue('rent_duration_max_days') || 0;
  const maxCost = getFieldValue(max_cost) || 0;
  const lateFee = getFieldValue(late_fee_plan_id) || 0;
  const totalValue =
    (+rentDurationMaxDays - rentDurationDaysValue - 1) *
    getMeteredPrice(lateFee);

  return (
    <>
      <Title level={4} className="membership__level__settings--header">
        Membership settings for <b>{containerType.name}</b> Container Type
      </Title>
      <Form.Item hidden name={container_type_id}>
        <Input />
      </Form.Item>
      <Form.Item
        label={`Stripe Daily ${containerType.name} Late Fee Plan`}
        name={late_fee_plan_id}
        rules={[{ required: true, message: 'Please select stripe plan id!' }]}
      >
        <StripePlanSelect
          placeholder="Plan ID"
          filter={{ usage_type: 'metered' }}
        />
      </Form.Item>
      <Form.Item
        label={`Stripe Lost ${containerType.name} Fee Plan`}
        name={lost_fee_plan_id}
        rules={[{ required: true, message: 'Please select stripe plan id!' }]}
      >
        <StripePlanSelect
          placeholder="Plan ID"
          filter={{ usage_type: 'metered' }}
        />
      </Form.Item>

      <Card title={`Summary ${containerType.name}`}>
        <ul>
          <li>
            User have <b>{rentDurationDays}</b> days to return a{' '}
            {containerType.name};
          </li>
          <li>
            User will be charged first time on <b>{rentDurationDaysValue}th</b>{' '}
            day;
          </li>
          <li>
            Late Fees will be charged till <b>{rentDurationMaxDays}th</b> day;
          </li>
          <li>
            From <b>{rentDurationDaysValue}</b> to{' '}
            <b>{rentDurationMaxDays}th</b>(excluding) day in total{' '}
            <b>${totalValue}</b>;
          </li>
          <li>
            On <b>{rentDurationMaxDays}th</b> day <b>${maxCost - totalValue}</b>{' '}
            lost fee will be charged.
          </li>
        </ul>
      </Card>
    </>
  );
};

const MembershipForm = ({ handleSubmit, membership }: any) => {
  const [loading, setLoading] = useState(false);
  const mode = membership ? 'update' : 'create';

  const [form] = Form.useForm();

  useEffect(() => {
    membership && form.setFieldsValue(membership);
  }, [form, membership]);

  const { data: stripePlans } = useQuery(QUERY_STRIPE_PLANS);
  const { data: containerTypesData } = useQuery(QUERY_LIST_CONTAINER_TYPES);

  const submit = useCallback(
    async (values: any) => {
      setLoading(true);
      await handleSubmit({
        ...values,
        trial_length_days: +values.trial_length_days,
        rent_duration_days: +values.rent_duration_days,
        rent_duration_max_days: +values.rent_duration_max_days,
        max_cups_per_month: values.max_cups_per_month
          ? +values.max_cups_per_month
          : null,
      });
      setLoading(false);
    },
    [handleSubmit],
  );

  // useEffect(() => {
  //   if (containerTypesData && (membership || mode === 'create')) {
  //     form.setFieldsValue({
  //       membershipContainerTypes: containerTypesData.listContainerType.map(
  //         (ct: any) => {
  //           const meta = membership?.membershipContainerTypes.find(
  //             (c: any) => c.container_type_id === ct.type_id,
  //           );
  //           return {
  //             container_type_id: ct.type_id,
  //             late_fee_plan_id: meta?.late_fee_plan_id,
  //             lost_fee_plan_id: meta?.lost_fee_plan_id,
  //             max_cost: meta?.max_cost,
  //             id: meta?.id,
  //           };
  //         },
  //       ),
  //     });
  //   }
  // }, [form, mode, membership, containerTypesData]);

  const getMeteredPrice = useCallback(
    (plan_id: string) => {
      const plan = stripePlans
        ? stripePlans.listStripePlan.find((p: any) => p.id === plan_id)
        : null;
      return plan ? plan.amount / 100 : 0;
    },
    [stripePlans],
  );

  return (
    <Row gutter={10} className="memberships">
      <Form
        form={form}
        layout="vertical"
        name="membership_form"
        onFinish={submit}
      >
        <Row>
          <Col span={24}>
            <Title level={2}>
              {mode === 'create'
                ? 'Create Membership'
                : `Update Membership "${membership.name}"`}
            </Title>
          </Col>

          <Col span={24} style={{ margin: '20px 0' }}>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Name of Membership"
                  name="name"
                  rules={[{ required: true, message: 'Please input name!' }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  label="Description of Membership"
                  name="description"
                  rules={[
                    { required: true, message: 'Please input description!' },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  label="Rent duration days"
                  name="rent_duration_days"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Rent duration days!',
                    },
                  ]}
                >
                  <Input type="number" placeholder="Rent duration days" />
                </Form.Item>
                <Form.Item
                  label="Rent duration max days (lost cup will be charged on that day)"
                  name="rent_duration_max_days"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Rent duration max days!',
                    },
                  ]}
                >
                  <Input type="number" placeholder="Rent duration max days" />
                </Form.Item>
                <Form.Item
                  label="Trial length days"
                  name="trial_length_days"
                  rules={[
                    {
                      required: true,
                      message: 'Please input trial length days!',
                    },
                  ]}
                >
                  <Input type="number" placeholder="Trial length days" />
                </Form.Item>
                <Form.Item
                  label="Max containers per month"
                  name="max_cups_per_month"
                >
                  <Input type="number" placeholder="Max containers per month" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Stripe Monthly Plan"
                  name="stripe_plan_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select stripe plan id!',
                    },
                  ]}
                >
                  <StripePlanSelect
                    placeholder="Plan ID"
                    filter={{ usage_type: 'licensed' }}
                  />
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.rent_duration_days !==
                      curValues.rent_duration_days ||
                    prevValues.rent_duration_max_days !==
                      curValues.rent_duration_max_days ||
                    prevValues.membershipContainerTypes !==
                      curValues.membershipContainerTypes
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue('membershipContainerTypes')?.map(
                      (ct: any, i: number) => {
                        const containerType =
                          containerTypesData?.listContainerType.find(
                            (c: any) => c.type_id === ct.container_type_id,
                          );
                        return (
                          <React.Fragment
                            key={`${ct.id}_${ct.container_type_id}`}
                          >
                            {containerType ? (
                              <MembershipContainerTypeSettingsForm
                                containerType={containerType}
                                containerTypeIndex={i}
                                form={form}
                                getMeteredPrice={getMeteredPrice}
                              />
                            ) : null}
                          </React.Fragment>
                        );
                      },
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {mode === 'create' ? 'Save' : 'Update'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default MembershipForm;
