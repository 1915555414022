import gql from 'graphql-tag';

export const QUERY_LIST_ERRORS = gql`
  query (
    $where: WhereErrorEntityInput
    $pagination: PaginationInput
    $order: [OrderInput!]
  ) {
    listErrorEntity(where: $where, pagination: $pagination, order: $order) {
      total
      list {
        error_id
        user_id

        error_body
        related_data

        created_on
        deleted_on

        user {
          full_name
        }
      }
    }
  }
`;

export const QUERY_AMOUNT_OF_ERRORS = gql`
  query {
    listErrorEntity(where: { deleted_on: { isNull: true } }) {
      total
    }
  }
`;

export const MUTATION_ERROR_BULK_UPDATE = gql`
  mutation UpdateError(
    $primaryKeys: [ErrorEntityPrimaryKeys!]!
    $input: ErrorEntityBulkInput!
  ) {
    updateBulkErrorEntity(primaryKeys: $primaryKeys, input: $input)
  }
`;
