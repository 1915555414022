import gql from 'graphql-tag';

export const QUERY_LIST_MEMBERSHIPS = gql`
  query ListMemberships(
    $pagination: PaginationInput
    $order: [OrderInput!]
    $where: WhereMembershipLevelInput
  ) {
    listMembershipLevel(pagination: $pagination, where: $where, order: $order) {
      total
      list {
        level_id
        name
        description
        stripe_price {
          amount
        }
        stripe_plan_id

        clientMemberships {
          rent_duration_days
          rent_duration_max_days
          trial_length_days
          max_containers_per_month
        }
      }
    }
  }
`;

export const QUERY_GET_MEMBERSHIP = gql`
  query GetMembership($level_id: ID!) {
    getMembershipLevel(level_id: $level_id) {
      level_id
      name
      description
      stripe_price {
        amount
      }

      stripe_plan_id

      clientMemberships {
        rent_duration_days
        rent_duration_max_days
        trial_length_days
        max_containers_per_month
      }
    }
  }
`;

export const MUTATION_CREATE_MEMBERSHIP = gql`
  mutation CreateMembership($input: MembershipLevelInput!) {
    createMembershipLevel(input: $input) {
      level_id
    }
  }
`;

export const MUTATION_REMOVE_MEMBERSHIP = gql`
  mutation RemoveMembership($level_id: ID!) {
    removeMembershipLevel(level_id: $level_id)
  }
`;

export const MUTATION_UPDATE_MEMBERSHIP = gql`
  mutation UpdateMembership($level_id: ID!, $input: MembershipLevelInput!) {
    updateMembershipLevel(level_id: $level_id, input: $input) {
      level_id
    }
  }
`;
