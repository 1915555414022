import gql from 'graphql-tag';

export const CORP_CLIENT_HOSTED_PAGE_QUERY = gql`
  query CorpClientHostedPageQuery($corpClientUuid: String) {
    hostedPage(corpClientUuid: $corpClientUuid) {
      totalCups
      totalBowls
      co2Saved
      wasteAvoided
      water
    }
  }
`;
