import React, { useContext, useEffect, useMemo } from 'react';

import { UserContext } from '../../../contexts/UserContext';
import { useCorporateClients } from '../../../hooks/useCorporateClients';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';

const tempUUID = '13c33df8-0654-11ec-9a03-0242ac130003';

export const getCorporateClientReportLink = (uuid?: string) =>
  `https://lookerstudio.google.com/embed/reporting/d61d1c1a-21e0-4380-8f61-981c9de8b4ea/page/ks7BE?params=%7B%22settings.uuid%22:%22${uuid}%22,%22checkouts.uuid%22:%22${uuid}%22,%22current.uuid%22:%22${uuid}%22%7D`;

const Index = () => {
  const { dbUser } = useContext(UserContext);
  const { corporateClients, getCorporateClients } = useCorporateClients();

  useEffect(() => {
    getCorporateClients();
  }, [getCorporateClients]);

  const reportLink = useMemo(
    () => getCorporateClientReportLink(tempUUID),
    [(corporateClients?.length, dbUser?.isCorporateAdmin)],
  );
  if (!dbUser) {
    return null;
  }

  if (!dbUser?.userPermissions.AdminLogin) {
    return <PermissionsAlert />;
  }

  return (
    // 100(header) + 50(25 - top, 25 - bottom. App content padding)
    <div style={{ height: '100vh' }}>
      <iframe
        src={reportLink}
        style={{ width: '100%', height: '100%', borderWidth: 0 }}
      />
    </div>
  );
};

export default Index;
