import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  message,
  Popconfirm,
} from 'antd';
import { useMutation } from '@apollo/client';
import PermissionsSelect from '../../../../components/Select/PermissionsSelect';
import { QRCode } from 'react-qrcode-logo';
import CorporateClientSelect from '../../../../components/Select/CorporateClientSelect';
import { Buffer } from 'buffer';
import { UserContext } from '../../../../contexts/UserContext';
import { MUTATION_UNFREEZE_USER } from '../../../../queries/UserQueries';

const { Title, Text } = Typography;

const UserForm = ({ handleSubmit, user, refetchUser }: any) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { dbUser } = useContext(UserContext);
  const mode = user ? 'update' : 'create';

  const [unfreezeUser] = useMutation(MUTATION_UNFREEZE_USER);

  const [form] = Form.useForm();

  useEffect(() => {
    user &&
      form.setFieldsValue({
        ...user,
        permissions: (user.permissions || []).map(
          (c: any) => c.permission_id + '',
        ),
        userCorporateClients: (user.userCorporateClients || []).map(
          (c: any) => c.corporate_client_id + '',
        ),
      });
  }, [form, user]);

  const submit = useCallback(async () => {
    const values = await form.validateFields();

    setLoading(true);
    await handleSubmit(values);
    setLoading(false);
  }, [form, handleSubmit]);

  const userMembership = useMemo(
    () => user.user_membership.find((m: Record<string, any>) => !m.to),
    [user],
  );

  return (
    <Row gutter={10}>
      <Form layout="vertical" name="user_create" form={form} onFinish={submit}>
        <Row>
          <Col span={24}>
            <Title level={2}>
              {mode === 'create'
                ? 'Create User'
                : `Update User "${
                    user.first_name
                      ? user.first_name + ' ' + user.last_name
                      : user.email
                  }"`}
            </Title>
          </Col>

          {user.firebase_user_id && (
            <Col span={24}>
              <QRCode
                value={Buffer.from(`user:${user.firebase_user_id}`).toString(
                  'base64',
                )}
              />
            </Col>
          )}

          <Col span={24} style={{ margin: '20px 0' }}>
            <Form.Item label="Email" name="email">
              <Input type="email" placeholder="First Name" disabled />
            </Form.Item>

            <Form.Item label="First Name" name="first_name">
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name">
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item label="Membership Level">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  type={user.current_client_level ? 'success' : 'danger'}
                  strong
                >
                  {user.current_client_level?.membershipLevel?.name ||
                    'No any active subscription'}
                </Text>
                {!!userMembership?.locked_by_transaction_id && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        border: '1px solid red',
                        marginLeft: 12,
                        marginRight: 12,
                        padding: '4px',
                      }}
                    >
                      <Text type={'danger'} strong>
                        FROZEN
                      </Text>
                    </div>
                    {dbUser?.userPermissions.DangerZone && (
                      <>
                        <div className="assignment-space space-lg" />
                        {contextHolder}
                        <Popconfirm
                          title="Did you void the user's fee already?"
                          onConfirm={async () => {
                            const unfreezeRes = await unfreezeUser({
                              variables: {
                                user_id: user.user_id,
                              },
                            });

                            if (unfreezeRes?.data?.unfreezeUser?.success) {
                              refetchUser();
                            } else if (
                              unfreezeRes?.data?.unfreezeUser?.error_message
                            ) {
                              messageApi.error(
                                unfreezeRes.data.unfreezeUser.error_message
                                  .message,
                              );
                            }
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            style={{
                              border: '1px solid green',
                              color: 'green',
                            }}
                          >
                            Activate
                          </Button>
                        </Popconfirm>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Community">
              <Text
                type={user.current_client_level ? 'success' : 'danger'}
                strong
              >
                {user.current_client_level?.corporateClient
                  ? user.current_client_level?.corporateClient?.name
                  : user.current_client_level
                  ? 'DEFAULT'
                  : 'No any active subscription with some community'}
              </Text>
            </Form.Item>
            <Form.Item label="Payment Processor">
              <Text type={userMembership ? 'success' : 'danger'} strong>
                {userMembership?.paymentProcessor?.name || 'Not Found'}
              </Text>
            </Form.Item>

            <Form.Item label="Admin Permissions" name="permissions">
              <PermissionsSelect placeholder="Select admin permissions" />
            </Form.Item>

            <Form.Item
              label="Admin Of Corporate Clients"
              name="userCorporateClients"
            >
              <CorporateClientSelect
                mode="multiple"
                placeholder="Select Corporate Clients"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {mode === 'create' ? 'Save' : 'Update'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default UserForm;
