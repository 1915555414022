import React, { forwardRef } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_USER_SIMPLE } from 'queries/UserQueries';

const { Option } = Select;

const baseVariables = {
  pagination: {
    size: 10,
    page: 1,
  },
};
const UserSearchSelect = forwardRef((props: any, ref: any) => {
  const { data, loading, error, refetch } = useQuery(QUERY_LIST_USER_SIMPLE, {
    variables: {
      ...baseVariables,
      where: {},
    },
  });

  const fetchLocations = debounce(async (value: string) => {
    refetch({
      ...baseVariables,
      where: {
        or: [
          { first_name: { contains: value } },
          { last_name: { contains: value } },
        ],
      },
    });
  }, 800);

  return (
    <Select
      ref={ref}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={false}
      showSearch
      onSearch={fetchLocations}
      style={{ width: '100%' }}
      {...props}
    >
      {data && !error
        ? data.listUser.list.map((l: any) => {
            return (
              <Option key={l.user_id} value={l.user_id}>
                {l.full_name || l.email}
              </Option>
            );
          })
        : null}
    </Select>
  );
});

UserSearchSelect.displayName = 'UserSearchSelect';

export default UserSearchSelect;
