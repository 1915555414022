import React from 'react';
import { Alert } from 'antd';

import './PermissionsAlert.sass';

const PermissionsAlert = () => {
  return (
    <div className="container">
      <Alert
        message="Access denied! You don't have permission for viewing this page!"
        type="error"
      />
    </div>
  );
};

export default PermissionsAlert;
