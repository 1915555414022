export default function stripTypename(obj: any) {
  if (typeof obj === 'object') {
    delete obj.__typename;

    for (const key in obj) {
      obj[key] = stripTypename(obj[key]);
    }
  }

  return obj;
}
