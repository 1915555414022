import React, { useContext, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ROUTES from 'constants/routes';

import { UserContext } from '../../../contexts/UserContext';
import { useCorporateClients } from '../../../hooks/useCorporateClients';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';

const mainReportLink =
  'https://lookerstudio.google.com/embed/reporting/60ec72af-37a5-4a66-90ad-958d4df52caa/page/tEnnC';

export const getCorporateClientReportLink = (uuid?: string) =>
  `https://lookerstudio.google.com/embed/reporting/ca03be3a-8daa-498a-bd29-9f62549beb7c/page/p_d0ezqb5z5c?params=%7B%22fees.uuid%22:%22${uuid}%22,%22users.uuid%22:%22${uuid}%22,%22locations.uuid%22:%22${uuid}%22,%22checkouts.uuid%22:%22${uuid}%22,%22container.uuid%22:%22${uuid}%22,%22mealperiod.uuid%22:%22${uuid}%22,%22hourly.uuid%22:%22${uuid}%22,%22charge.uuid%22:%22${uuid}%22,%22operations.uuid%22:%22${uuid}%22%7D`;

const Index = () => {
  const { dbUser } = useContext(UserContext);
  const { corporateClients, getCorporateClients } = useCorporateClients();

  useEffect(() => {
    getCorporateClients();
  }, [getCorporateClients]);

  const reportLink = useMemo(
    () =>
      dbUser?.isCorporateAdmin && corporateClients?.length === 1
        ? getCorporateClientReportLink(corporateClients[0].uuid)
        : mainReportLink,
    [corporateClients?.length, dbUser?.isCorporateAdmin],
  );
  if (!dbUser) {
    return null;
  }

  if (
    !dbUser?.userPermissions.AdminReports &&
    !dbUser?.userPermissions.BasicReports
  ) {
    return <PermissionsAlert />;
  }

  return (
    // 100(header) + 50(25 - top, 25 - bottom. App content padding)
    <div style={{ height: 'calc(100vh - 150px)' }}>
      {dbUser?.isCorporateAdmin && corporateClients?.length > 1 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
          }}
        >
          {corporateClients.map((client: Record<any, any>) => (
            <Link
              key={client.corporate_client_id}
              to={{
                pathname: `${ROUTES.REPORTS_DASHBOARD}/${client.uuid}`,
              }}
              style={{ marginBottom: '20px' }}
            >
              {client.name}
            </Link>
          ))}
        </div>
      ) : !dbUser?.userPermissions.AdminReports && !dbUser.isCorporateAdmin ? (
        <PermissionsAlert />
      ) : (
        <iframe src={reportLink} style={{ width: '100%', height: '100%' }} />
      )}
    </div>
  );
};

export default Index;
