import React, { useEffect, useState } from 'react';
import './CorpClientHostedPage.sass';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useMatch, useSearchParams } from 'react-router-dom';
import { CORP_CLIENT_HOSTED_PAGE_QUERY } from './query';
import { useStateLocal } from './useStateLocal';
import moment from 'moment';
import numeral from 'numeral';

import logo from './images/logo.png';
import qrcode from './images/qrcode.png';
import globalWarming from './images/global-warming.png';
import showerWater from './images/shower-water.png';
import trashTruck from './images/trash-truck.png';
import Loader from '../../components/Loader/Loader';
import { checkIfValidUUID } from 'utils/checkIfValidUUID';

const REFRESH_EVERY = 600000; // 10 min

const getImpactValue = (value: number | undefined) =>
  value && value > 0 ? value.toFixed(1) : 0;

export const CorpClientHostedPage = () => {
  const match = useMatch('/tv/:ccUuid');
  const [searchParams] = useSearchParams();
  const admin = searchParams.get('admin');
  const ccUuid = match?.params.ccUuid;
  const [pollInterval, setPollInterval] = useState<number>();
  const [fetchPolicy, setFetchPolicy] =
    useState<WatchQueryFetchPolicy>('cache-only');
  const [{ cups, bowls, co2Saved, wasteAvoided, waterSaved }, setState] =
    useStateLocal(
      {
        cups: 0,
        bowls: 0,
        co2Saved: 0,
        wasteAvoided: 0,
        waterSaved: 0,
      },
      `cc-tv-${ccUuid}`,
    );

  const logoUri =
    ccUuid && checkIfValidUUID(ccUuid)
      ? `https://storage.googleapis.com/usefull-us-prod/corp_clients/${ccUuid}/logo.png`
      : logo;

  const { data, loading, refetch } = useQuery(CORP_CLIENT_HOSTED_PAGE_QUERY, {
    variables: { corpClientUuid: ccUuid || null },
    pollInterval,
    fetchPolicy,
    context: { noauth: true },
  });

  useEffect(() => {
    if (admin) {
      setFetchPolicy('cache-first');
      setPollInterval(undefined);
    } else {
      setFetchPolicy('cache-only');
      setPollInterval(REFRESH_EVERY);
    }
  }, [admin]);

  useEffect(() => {
    const tvLastUpdatedOn = localStorage.getItem('tv_last_updated_on');

    if (
      tvLastUpdatedOn &&
      moment().diff(moment(tvLastUpdatedOn), 'milliseconds') >= REFRESH_EVERY
    ) {
      refetch({ corpClientUuid: ccUuid || null });
    }

    if (!tvLastUpdatedOn) {
      localStorage.setItem('tv_last_updated_on', moment().toString());
    }
  }, []);

  useEffect(() => {
    if (data) {
      localStorage.setItem('tv_last_updated_on', moment().toString());
      setState({
        cups: data?.hostedPage?.totalCups ?? 0,
        bowls: data?.hostedPage?.totalBowls ?? 0,
        co2Saved: data?.hostedPage?.co2Saved ?? 0,
        wasteAvoided: data?.hostedPage?.wasteAvoided ?? 0,
        waterSaved: data?.hostedPage?.water ?? 0,
      });
    }
  }, [data?.hostedPage, setState]);

  useEffect(() => {
    if (!wasteAvoided) {
      refetch({ corpClientUuid: ccUuid || null });
    }
  }, [wasteAvoided]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="cc-hosted-page">
      <div className="cc-hosted-page-content">
        <header className="cc-hosted-page-header">
          <img
            alt="client logo"
            src={logoUri}
            className="cc-client-logo-image"
          />
        </header>

        <section className="cc-hosted-page-info">
          <div className="cc-hosted-page-impact">
            <h2 className="cc-hosted-page-title">impact through today:</h2>
            <h3 className="cc-hosted-page-date">
              {moment().format('MMMM DD, YYYY')}
            </h3>
            <p className="cc-hosed-page-count">
              {numeral(cups + bowls).format('0,0')}
            </p>
            <p className="cc-hosted-page-text">containers checked out</p>
          </div>

          <div className="cc-hosted-page-saved">
            <h2 className="cc-hosted-page-title">together we have saved:</h2>

            <div className="saved-items">
              <div className="saved-item">
                <div className="saved-item-image-container">
                  <img
                    alt="trash"
                    src={trashTruck}
                    className="saved-item-image"
                  />
                </div>
                <p className="saved-item-label">
                  {numeral(getImpactValue(wasteAvoided)).format('0,0.0')} lbs
                  <br />
                  of trash
                </p>
              </div>

              <div className="saved-item">
                <div className="saved-item-image-container">
                  <img
                    alt="water"
                    src={showerWater}
                    className="saved-item-image"
                  />
                </div>
                <p className="saved-item-label">
                  {numeral(getImpactValue(waterSaved)).format('0,0.0')} gal
                  <br />
                  of water
                </p>
              </div>

              <div className="saved-item">
                <div className="saved-item-image-container">
                  <img
                    alt="global-warming"
                    src={globalWarming}
                    className="saved-item-image"
                  />
                </div>
                <p className="saved-item-label">
                  {numeral(getImpactValue(co2Saved)).format('0,0.0')} lbs
                  <br />
                  of emissions
                </p>
              </div>
            </div>

            <p className="saved-item-description">
              thank you for doing your part to help our
              <br />
              community and the planet
            </p>
          </div>
        </section>

        <footer className="cc-hosted-page-footer">
          <div>
            <a href="http://onelink.to/x2hs5d" target="_blank" rel="noreferrer">
              <img alt="qr code" src={qrcode} className="cc-client-qr-code" />
            </a>
          </div>

          <div>
            <img
              alt="USEFULL logo"
              src={logo}
              className="cc-client-logo-image cc-client-footer-logo"
            />
          </div>

          <div>
            <h1 className="cc-hosted-page-usefull">USEFULL.US</h1>
          </div>
        </footer>
      </div>
    </div>
  );
};
