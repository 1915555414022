import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';

import routes from 'constants/routes';

import { QUERY_LIST_LOCATION_SIMPLE } from '../../../queries/LocationQueries';
import { MUTATION_REMOVE_BIN, QUERY_LIST_BINS } from './queries';
import { FULL_DATE } from '../../../constants/dateformats';
import AssignToLocationModal from './components/AssignToLocation';
import { Link } from 'react-router-dom';
import { QUERY_LIST_CORPORATE_CLIENTS } from '../../../queries/DictionariesQueries';
import { UserContext } from '../../../contexts/UserContext';
import {
  MUTATION_SET_CORPORATE_CLIENT_TO_BINS,
  PURE_QUERY_LIST_BINS,
} from './queries';
import printOptions from '../../../constants/PrintOptions';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';

const Index = () => {
  const { dbUser } = useContext(UserContext);
  const { setTableRef, setSelectedRowKeys, refetch } = useTable();
  const [assignLocationRecords, setAssignLocationRecords] = useState<any[]>([]);

  const {
    data: locations,
    error: locationsError,
    loading: locationsLoading,
  } = useQuery(QUERY_LIST_LOCATION_SIMPLE);
  const {
    data: corpClients,
    error: corpClientsError,
    loading: corpClientsLoading,
  } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
    variables: {
      order: [{ field: 'name', order: 'ASC' }],
      where: dbUser?.isCorporateAdmin
        ? {
            corporate_client_id: {
              in: dbUser?.userCorporateClients?.map(
                (uc) => uc.corporate_client_id,
              ),
            },
          }
        : {},
    },
  });
  const [setCorporateClientToBins] = useMutation(
    MUTATION_SET_CORPORATE_CLIENT_TO_BINS,
    {
      refetchQueries: [PURE_QUERY_LIST_BINS],
    },
  );
  const [deleteBin] = useMutation(MUTATION_REMOVE_BIN);

  const columns = useMemo(
    () => [
      {
        title: 'Bin ID',
        dataIndex: 'bin_id',
        render: (bin_id: string, record: any) => (
          <Link to={routes.BINS + '/' + bin_id + '/edit'}>
            {bin_id} {record.description ? ' - ' + record.description : null}
          </Link>
        ),
        sorter: true,
        width: 130,
      },
      {
        title: 'Corporate Client',
        dataIndex: ['corporateClient', 'name'],
        key: 'corporate_client_id',
        sorter: true,
        filters:
          corpClients && !corpClientsError
            ? corpClients.listCorporateClient.map((l: any) => ({
                value: l.corporate_client_id,
                text: l.name,
              }))
            : [],
        width: 160,
      },
      {
        title: 'Location Pretty Name',
        dataIndex: ['location', 'pretty_name'],
        key: 'location.pretty_name',
        sorter: true,
        width: 120,
      },
      {
        title: 'Location',
        dataIndex: ['location', 'name'],
        key: 'location_id',
        sorter: true,
        filters:
          locations && !locationsError
            ? locations.listLocation.list.map((l: any) => ({
                value: l.location_id,
                text: l.name,
              }))
            : [],
        width: 180,
      },
      {
        title: 'Assigned On',
        dataIndex: 'assigned_on',
        render: (assigned_on: any) =>
          assigned_on ? moment(assigned_on).format(FULL_DATE) : ' - ',
        sorter: true,
        width: 180,
      },
    ],
    [locations, locationsError],
  );

  const actions: any[] = [
    {
      label: 'Assign Location',
      run: async (records: any[]) => {
        setAssignLocationRecords(records);
      },
    },
    {
      label: 'Set Corporate Client',
      type: 'primary',
      isDropdown: true,
      dropdownItems:
        corpClients && !corpClientsError
          ? corpClients.listCorporateClient.map((l: any) => ({
              label: l.name,
              key: l.corporate_client_id,
            }))
          : [],
      runItem: async (records: any[], key: string) => {
        await setCorporateClientToBins({
          variables: {
            bin_ids: records.map((c) => c.bin_id),
            corporate_client_id: key,
          },
        });
      },
    },
    {
      label: 'Print QR Codes',
      type: 'primary',
      isDropdown: true,
      dropdownItems: printOptions,
      runItem: async (records: any[], key: string) => {
        const api = process.env.REACT_APP_GRAPHQL_SERVER_URI || '';

        window.open(
          api.replace('/graphql', '') +
            `/print?type=bin&format=${key}&` +
            records.map((bin: any) => `id[]=${bin.bin_id}`).join('&'),
          '_blank',
        );
      },
    },
    {
      label: 'Delete Bin',
      type: 'danger',
      onlyOne: true,
      run: async ([record]: any[]) => {
        await deleteBin({
          variables: { bin_id: record.bin_id },
          refetchQueries: [{ query: QUERY_LIST_BINS }],
        });
        refetch();
      },
    },
    {
      label: 'Create Return Bins',
      type: 'primary',
      to: routes.BINS + '/create',
    },
  ];

  if (locationsLoading || corpClientsLoading) {
    return <Loader />;
  }

  if (
    !dbUser?.userPermissions.ViewBins &&
    !dbUser?.userPermissions.ManageBins
  ) {
    return <PermissionsAlert />;
  }

  return (
    <>
      <AssignToLocationModal
        visible={!!assignLocationRecords?.length}
        records={assignLocationRecords}
        onClose={async (updated: boolean) => {
          setAssignLocationRecords([]);

          if (updated) {
            setSelectedRowKeys([]);
            await refetch();
          }
        }}
      />

      <CCCTable
        ref={setTableRef}
        query={QUERY_LIST_BINS}
        queryKey="listCollectionBin"
        rowKey="bin_id"
        columns={columns}
        actions={dbUser?.userPermissions.ManageBins ? actions : []}
      />
    </>
  );
};

export default Index;
