import gql from 'graphql-tag';

export const MUTATION_CHANGE_STATUS_CONTAINER = gql`
  mutation ContainerStatusChange(
    $container_id: ID!
    $status_id: ID!
    $additionalData: AnyScalar!
  ) {
    containerStatusChange(
      container_id: $container_id
      status_id: $status_id
      additionalData: $additionalData
    ) {
      error_message {
        message
      }
    }
  }
`;

export const MUTATION_CREATE_BULK_CONTAINER = gql`
  mutation CreateBulkContainer($input: [ContainerInput!]!) {
    createBulkContainer(input: $input) {
      container_id
    }
  }
`;

export const MUTATION_BULK_UPDATE_CONTAINER = gql`
  mutation UpdateBulkContainer(
    $primaryKeys: [ContainerPrimaryKeys!]!
    $input: ContainerBulkUpdateInput!
  ) {
    updateBulkContainer(primaryKeys: $primaryKeys, input: $input)
  }
`;

export const MUTATION_UPDATE_CONTAINER = gql`
  mutation UpdateContainer($container_id: ID!, $input: ContainerInput!) {
    updateContainer(container_id: $container_id, input: $input) {
      container_id
    }
  }
`;

export const PURE_QUERY_LIST_CONTAINERS = 'ListContainers';
export const QUERY_LIST_CONTAINERS = gql`
  query ListContainers(
    $pagination: PaginationInput
    $where: WhereContainerInput
    $order: [OrderInput!]
  ) {
    listContainer(pagination: $pagination, where: $where, order: $order) {
      total
      list {
        container_id
        unique_name

        status {
          status_id
          name
        }

        type {
          type_id
          name
          icon
          sku
        }

        corporateClient {
          corporate_client_id
          name
        }

        last_location {
          location_id
          name
        }

        qr_code_printed_on
        qr_batch
        created_at
        status_updated_on
        assignments_amount
      }
    }
  }
`;

export const QUERY_LOCATIONS = gql`
  query ListLocation($pagination: PaginationInput, $where: WhereLocationInput) {
    listLocation(pagination: $pagination, where: $where) {
      list {
        location_id
        name
      }
      total
    }
  }
`;

export const QUERY_CONTAINER_STATUSES = gql`
  {
    listContainerStatus {
      status_id
      name
    }
  }
`;

export const QUERY_GET_CONTAINER = gql`
  query GetContainer($container_id: ID!) {
    getContainer(container_id: $container_id) {
      container_id
      unique_name
      type_id

      type {
        name
      }
    }
  }
`;

export const MUTATION_SET_QR_BATCH = gql`
  mutation SetQrBatch($container_ids: [ID!]!) {
    setQrBatch(container_ids: $container_ids)
  }
`;

export const MUTATION_SET_CORPORATE_CLIENT_TO_CONTAINERS = gql`
  mutation SetCorporateClientToContainers(
    $container_ids: [ID!]!
    $corporate_client_id: ID!
  ) {
    setCorporateClientToContainers(
      container_ids: $container_ids
      corporate_client_id: $corporate_client_id
    )
  }
`;

export const QUERY_GET_QR_BATCHES = gql`
  query GetQrBatches {
    qrBatchList
  }
`;
