import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_ROLE } from 'queries/DictionariesQueries';

const { Option } = Select;

const RoleSelect = forwardRef(({ ...props }: any, ref: any) => {
  const baseVariables = {
    pagination: {
      size: 10,
      page: 1,
    },
  };
  const { data, error } = useQuery(QUERY_LIST_ROLE, {
    variables: {
      ...baseVariables,
      where: {},
    },
  });

  return (
    <Select ref={ref} style={{ width: '100%' }} {...props}>
      {data && !error
        ? data.listRole.map((l: any) => (
            <Option key={l.role_id} value={`${l.role_id}`}>
              {l.name}
            </Option>
          ))
        : null}
    </Select>
  );
});

RoleSelect.displayName = 'RoleSelect';

export default RoleSelect;
