import React, { CSSProperties, useMemo, useState } from 'react';
import moment from 'moment';
import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import {
  MUTATION_ERROR_BULK_UPDATE,
  QUERY_AMOUNT_OF_ERRORS,
  QUERY_LIST_ERRORS,
} from 'queries/ErrorQueries';
import { useMutation } from '@apollo/client';
import { Modal } from 'antd';

const ViewJson = ({ json }: any) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return <span>Error parsing json</span>;
  }

  return <pre>{json ? JSON.stringify(JSON.parse(json), null, 3) : null}</pre>;
};

const Index = () => {
  const { setTableRef, refetch } = useTable();
  const [detailed, setDetailed] = useState<any | boolean>(false);
  const [field, setField] = useState<string>('');

  const [deleteErrors] = useMutation(MUTATION_ERROR_BULK_UPDATE);

  const linkPopupStyle = useMemo(
    () => ({
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '320px',
    }),
    [],
  ) as CSSProperties;

  const columns = useMemo(
    () => [
      {
        title: 'Error Id',
        dataIndex: 'error_id',
        sorter: true,
        width: 100,
      },
      {
        title: 'Created On',
        dataIndex: 'created_on',
        render: (data: string) => moment(data).format('LLL'),
        sorter: true,
        width: 160,
      },
      {
        title: 'User',
        dataIndex: ['user', 'first_name'],
        render: (_: string, record: any) => record.full_name,
        sorter: true,
        width: 160,
      },
      {
        title: 'Error Body',
        dataIndex: 'error_body',
        // sorter: true,
        width: 180,
        render: (data: string, record: any) => {
          return (
            <a
              href="#/"
              style={linkPopupStyle}
              onClick={(e: any) => {
                e.preventDefault();

                setField('error_body');
                setDetailed(record);
              }}
            >
              {data}
            </a>
          );
        },
      },
      {
        title: 'Related Data',
        dataIndex: 'related_data',
        // sorter: true,
        width: 180,
        render: (data: string, record: any) => {
          return (
            <a
              href="#/"
              style={linkPopupStyle}
              onClick={(e: any) => {
                e.preventDefault();

                setField('related_data');
                setDetailed(record);
              }}
            >
              {data}
            </a>
          );
        },
      },
    ],
    [linkPopupStyle],
  );

  const actions = [
    {
      label: 'Delete',
      type: 'danger',
      run: async (records: any[]) => {
        const primaryKeys = records.map((e: any) => ({
          error_id: e.error_id,
        }));

        await deleteErrors({
          variables: {
            primaryKeys,
            input: {
              deleted_on: new Date(),
            },
          },
          refetchQueries: [
            {
              query: QUERY_AMOUNT_OF_ERRORS,
            },
            {
              query: QUERY_LIST_ERRORS,
            },
          ],
        });

        refetch();
      },
    },
  ];

  return (
    <>
      <Modal
        visible={!!detailed}
        onCancel={() => setDetailed(false)}
        width={900}
      >
        <ViewJson json={detailed[field]} />
      </Modal>

      <CCCTable
        ref={setTableRef}
        where={{ deleted_on: { isNull: true } }}
        query={QUERY_LIST_ERRORS}
        queryKey="listErrorEntity"
        rowKey="error_id"
        columns={columns}
        actions={actions}
      />
    </>
  );
};

export default Index;
