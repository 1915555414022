import { useCallback, useState } from 'react';

export function useStateLocal<Value extends object>(
  initial: Value,
  key: string,
): [Value, (next: Value) => void] {
  const [value, setValue] = useState(() => {
    let persistedValue;
    try {
      const stringified = window.localStorage.getItem(key);
      persistedValue = stringified ? JSON.parse(stringified) : null;
    } catch (e) {
      return initial;
    }
    return persistedValue || initial;
  });

  return [
    value,
    useCallback(
      (nextValue) => {
        const stringified = JSON.stringify(nextValue);
        window.localStorage.setItem(key, stringified);
        setValue(nextValue);
      },
      [setValue, key],
    ),
  ];
}
