const ROUTES = {
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',

  TV_HOSTED_PAGE: '/tv',
  MAIN: '/',
  VERIFY_EMAIL: '/verify',
  TV_SHARED_PAGE: '/tv/:ccUuid/share',

  USERS: '/users',
  CORPORATE_CLIENTS: '/corporate_clients',
  CONTAINERS: '/containers',
  CONTAINER_TYPES: '/container/types',
  BOXES: '/boxes',
  LOADS: '/loads',
  CUP_HISTORY: '/cup/history',
  LOCATIONS: '/locations',
  BINS: '/bins',
  MEMBERSHIPS: '/memberships',
  TOAST_MESSAGES: '/toast-messages',
  LOCATION_USERS: '/location-users',
  ASSIGNMENTS: '/assignments',
  ERRORS: '/errors',
  REPORTS_DASHBOARD: '/report',
  FORBIDDEN: '/403',
};

export default ROUTES;
